

let domain = "https://localhost:7070"; 

if (window.location.hostname.includes("clixy.net")) {
  domain = "https://api.clixy.net";
} else if (window.location.hostname.includes("ezzeyfind.com")) {
  domain = "https://api.ezzeyfind.com";
}
export { domain };
//export const domain; 

export const mainSetting = {
  UploadRequestHeader: {
    Authorization: "Bearer " + localStorage.getItem("accessToken")
  },
  FCMToken: ""
};

export const toolbar = [
  ["bold", "italic", "underline", "link", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
  ["image"]
];
