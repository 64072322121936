// axios
import axios from "axios";





let domain = "https://localhost:7070"; // لوكال

if (window.location.hostname.includes("clixy.net")) {
  domain = "https://api.clixy.net"; //كليكسى
} else if (window.location.hostname.includes("ezzeyfind.com")) {
  domain = "https://api.ezzeyfind.com";  //ايزى فايند
}


export default axios.create({
  baseURL: domain
  // You can add your headers here
});
