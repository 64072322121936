/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
//import store from "@/store/store.js";
//import auth from "@/auth/authService";

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  // hash: false,

  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "Home",
          component: () => import("./views/Home/home.vue"),
          meta: {
            breadcrumb: [

              { title: "Home", url: "/Home/", active: true },
              { title: "Home", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "PushAllNotification",
          name: "PushAllNotification",
          component: () => import("./views/PushAllNotification/PushAllNotification.vue"),
          meta: {
            breadcrumb: [

              { title: "PushAllNotification", url: "/PushAllNotification", active: true },
              { title: "PushAllNotification", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/DashboardECommerce",
          name: "DashboardECommerce",
          component: () => import("./views/DashboardECommerce.vue"),
          meta: {
            breadcrumb: [

              { title: "DashboardECommerce", url: "/DashboardECommerce/", active: true },
              { title: "DashboardECommerce", active: true }
            ],
            rule: "anonymous"
          }
        },


        {
          path: "/UsersManagement/WatingUsers",
          name: "WatingUsers",
          component: () => import("./views/UsersManagement/WatingUsers.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/WatingUsers", active: true },
              { title: "WatingUsers", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/UsersManagement/Users",
          name: "Users",
          component: () => import("./views/UsersManagement/UsersManagement.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/Users", active: true },
              { title: "Users", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/ApexCharts",
          name: "ApexCharts",
          component: () => import("./views/charts-and-maps/charts/apex-charts/ApexCharts.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/AdsManagement/ApexCharts", active: true },
              { title: "ApexCharts", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Chartjs",
          name: "Chartjs",
          component: () => import("./views/charts-and-maps/charts/chartjs/Chartjs.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/AdsManagement/Chartjs", active: true },
              { title: "Chartjs", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Echarts",
          name: "Echarts",
          component: () => import("./views/charts-and-maps/charts/echarts/Echarts.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/AdsManagement/Echarts", active: true },
              { title: "Echarts", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          //show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin" ||(JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin"),

          path: "/UsersManagement/UserManagmentAddOrEdit/:ID?",
          name: "BrokerEdit",
          component: () => import("./views/UsersManagement/UserManagmentAddOrEdit.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/UsersManagement/UserManagmentAddOrEdit", active: true },
              { title: "UserManagmentAddOrEdit", active: true }
            ],
            rule: "anonymous",
            pageTitle: "UserManagmentAddOrEdit"
          }
        },
        {
          path: '/UsersManagement/UserDetails/:Id',
          name: 'UserDetails',
          component: () => import("./views/UsersManagement/UserDetails.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/UsersManagement/UserDetails", active: true },
              { title: "UserDetails", active: true }
            ],
            rule: "anonymous",
            pageTitle: "UserDetails"
          }
        },
        {
          path: "/AcceptRequests/",
          name: "AcceptRequests",
          component: () => import("./views/UsersManagement/AcceptRequests.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/UsersManagement/AcceptRequests", active: true },
              {  active: true }
            ],
            rule: "anonymous",
            pageTitle: "Registrationrequests"
          }
        },


        {
          path: "/UnitManagement",
          name: "UnitManagement",
          component: () => import("./views/UnitManagement/UnitManagement.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UnitManagement/UnitManagement", active: true },
              { title: "UnitManagement", active: true }
            ],
            rule: "anonymous"
          }
        },
        //UintManagmentAddOrEdit

        {
          path: "/UnitManagement/UintManagmentAddOrEdit/:ID?",
          name: "UintManagmentAddOrEdit",
          component: () => import("./views/UnitManagement/UintManagmentAddOrEdit.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/UnitManagement/UintManagmentAddOrEdit", active: true },
              { title: "UintManagmentAddOrEdit", active: true }
            ],
            rule: "anonymous",
            pageTitle: "UintManagmentAddOrEdit"
          }
        },

        {
          path: "/UnitManagement",
          name: "UnitManagement",
          component: () => import("./views/UnitManagement/UnitManagement.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UnitManagement/UnitManagement", active: true },
              { title: "UnitManagement", active: true }
            ],
            rule: "anonymous"
          }
        },
        //AddVisitOrRent.vue
        {
          path: "/VisitAndRentNotices/AddVisitOrRent/:ID?",
          name: "AddVisitOrRent",
          component: () => import("./views/VisitAndRentNotices/AddVisitOrRent.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/VisitAndRentNotices/AddVisitOrRent", active: true },
              { title: "AddVisitOrRent", active: true }
            ],
            rule: "anonymous",
            pageTitle: "AddVisitOrRent"
          }
        },

        {
          path: "/VisitAndRentNotices",
          name: "VisitAndRentNotices",
          component: () => import("./views/VisitAndRentNotices/VisitAndRentNotices.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/VisitAndRentNotices/VisitAndRentNotices", active: true },
              { title: "VisitAndRentNotices", active: true }
            ],
            rule: "anonymous"
          }
        },

        {
          path: "/CustomersService",
          name: "CustomersService",
          component: () => import("./views/CustomersService/CustomersService.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/CustomersService/CustomersService", active: true },
              { title: "CustomersService", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/CustomersService/CustomersServiceDetails/:ID?",
          name: "CustomersServiceDetails",
          component: () => import("./views/CustomersService/CustomersServiceDetails.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/CustomersService/CustomersServiceDetails", active: true },
              { title: "CustomersServiceDetails", active: true }
            ],
            rule: "anonymous",
            pageTitle: "CustomersServiceDetails"
          }
        },

        // {
        //   path: "/RealEstateServicesManagement/specialrequestsdetails",
        //   name: "specialrequestsdetails",
        //   component: () => import("./views/RealEstateServicesManagement/specialrequestsdetails.vue"),
        //  meta: {
        //    breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/specialrequestsdetails", active: true },
        //       { title: "specialrequestsdetails", active: true }
        //     ],
        //     rule: "anonymous",
        //     pageTitle: "specialrequestsdetails"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/propertyRequestDetails",
        //   name: "propertyRequestDetails",
        //   component: () => import("./views/RealEstateServicesManagement/propertyRequestDetails.vue"),
        //  meta: {
        //    breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/propertyRequestDetails", active: true },
        //       { title: "propertyRequestDetails", active: true }
        //     ],
        //     rule: "anonymous",
        //     pageTitle: "propertyRequestDetails"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/evaluationrequestsdetails",
        //   name: "evaluationrequestsdetails",
        //   component: () => import("./views/RealEstateServicesManagement/evaluationrequestsdetails.vue"),
        //  meta: {
        //    breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/evaluationrequestsdetails", active: true },
        //       { title: "evaluationrequestsdetails", active: true }
        //     ],
        //     rule: "anonymous",
        //     pageTitle: "evaluationrequestsdetails"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/contibuationsrequestdetails",
        //   name: "propertyRequestDetails",
        //   component: () => import("./views/RealEstateServicesManagement/contibuationsrequestdetails.vue"),
        //  meta: {
        //    breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/contibuationsrequestdetails", active: true },
        //       { title: "contibuationsrequestdetails", active: true }
        //     ],
        //     rule: "anonymous",
        //     pageTitle: "contibuationsrequestdetails"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/developmentrequestdetails",
        //   name: "developmentrequestdetails",
        //   component: () => import("./views/RealEstateServicesManagement/developmentrequestdetails.vue"),
        //  meta: {
        //    breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/developmentrequestdetails", active: true },
        //       { title: "developmentrequestdetails", active: true }
        //     ],
        //     rule: "anonymous",
        //     pageTitle: "developmentrequestdetails"
        //   }
        // },

        {
          path: "/Notifications",
          name: "Notifications",
          component: () => import("./views/Notifications/Notifications.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Notifications/Notifications", active: true },
              { title: "Notifications", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/UsersManagement/clientuser',
         name: 'ClientUser',
         props: true,
          component: () => import("./views/UsersManagement/clientuser.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/ClientUserDetails", active: true },
              { title: "ClientUserDetails", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/UsersManagement/EmployeeUser',
          name: 'EmployeeUser',
          props: true,
          component: () => import("./views/UsersManagement/EmployeeUser.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/EmployeeUserDetails", active: true },
              { title: "EmployeeUserDetails", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/Country',
          name: 'Country',
          props: true,
          component: () => import("./views/Settings/Country/Country.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/Country", active: true },
              { title: "Country", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/Privacy',
          name: 'Privacy',
          props: true,
          component: () => import("./views/Settings/Privacy/Privacy.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/Privacy", active: true },
              { title: "Privacy", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/BannerAds',
          name: 'BannerAds',
          props: true,
          component: () => import("./views/BannerAds/BannerAds.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/BannerAds/BannerAds", active: true },
              { title: "BannerAds", active: true }
            ],
            rule: "anonymous"
          }
        },

        {
          path: '/Notification/Complaint',
          name: 'Complaint',
          props: true,
          component: () => import("./views/Notification/Complaint.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "Notification/Complaint", active: true },
              { title: "Complaint", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Notification/ComplaintDetails/:Id',
          name: 'ComplaintDetails',
          props: true,
          component: () => import("./views/Notification/ComplaintDetails.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "Notification/ComplaintDetails", active: true },
              { title: "ComplaintDetails", active: true }
            ],
            rule: "anonymous",
           // pageTitle:"test"
          }
        },
        {
          path: '/Settings/Nationality',
          name: 'Nationality',
          props: true,
          component: () => import("./views/Settings/Nationality/Nationality.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/Nationality", active: true },
              { title: "Nationality", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/City',
          name: 'City',
          props: true,
          component: () => import("./views/Settings/City/City.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/settings/City", active: true },
              { title: "City", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/UnitType',
          name: 'UnitType',
          props: true,
          component: () => import("./views/Settings/UnitType/UnitType.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/settings/UnitType", active: true },
              { title: "UnitType", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/properties',
          name: 'properties',
          props: true,
          component: () => import("./views/Settings/properties/properties.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/settings/properties", active: true },
              { title: "properties", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/PropertySpecs',
          name: 'PropertySpecs',
          props: true,
          component: () => import("./views/Settings/PropertySpecs/PropertySpecs.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/settings/PropertySpecs", active: true },
              { title: "PropertySpecs", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/Settings/Region',
          name: 'Region',
          props: true,
          component: () => import("./views/Settings/Region/Region.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/Region", active: true },
              { title: "Region", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/UsersManagement/Broker/:Id',
          name: 'Broker',
          props: true,
          component: () => import("./views/UsersManagement/Broker.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/Broker", active: true },
              { title: "Broker", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/UsersManagement/ServiceProviderDetails/:Id',
          name: 'ServiceProviderDetails',
          props: true,
          component: () => import("./views/UsersManagement/ServiceProviderDetails.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/ServiceProviderDetails", active: true },
              { title: "ServiceProviderDetails", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: '/UsersManagement/UpdateBroker/:Id',
          name: 'UpdateBroker',
          props: true,
          component: () => import("./views/UsersManagement/UpdateBroker.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/UsersManagement/UpdateBroker", active: true },
              { title: "UpdateBroker", active: true }
            ],
            rule: "anonymous"
          }
        },
        // {
        //   path: '/UsersManagement/UpdateServiceProvider/:Id',
        //   name: 'UpdateServiceProvider',
        //   props: true,
        //   component: () => import("./views/UsersManagement/UpdateServiceProvider.vue"),
        //   meta: {
        //     breadcrumb: [

        //       { title: "", url: "/UsersManagement/UpdateServiceProvider", active: true },
        //       { title: "UpdateServiceProvider", active: true }
        //     ],
        //     rule: "anonymous"
        //   }
        // },

        {
          path: "/News",
          name: "News",
          component: () => import("./views/News/News.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/News/News", active: true },
              { title: "News", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/News/AddNews/:ID?",
          name: "AddNews",
          component: () => import("./views/News/AddOrEditNews.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/News/AddOrEditNews", active: true },
              { title: "AddOrEditNews", active: true }
            ],
            rule: "anonymous",
            pageTitle: "AddOrEditNews"
          }
        },


        {
          path: "/WorksScreen",
          name: "WorksScreen",
          component: () => import("./views/WorksScreen/WorksScreen.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/WorksScreen/WorksScreen", active: true },
              { title: "WorksScreen", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/CustomerAds/ShowAds",
          name: "ShowAds",
          component: () => import("./views/CustomerAds/ShowAds.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/CustomerAds/ShowAds", active: true },
              { title: "ShowAds", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/CustomerAds/AdsManagement",
          name: "AdsManagement",
          component: () => import("./views/AdsManagement/AdsManagement.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/CustomerAds/AdsManagement", active: true },
              { title: "AdsManagement", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/AdsManagement/AdDetails/:ID?",
          name: "AdDetails",
          component: () => import("./views/AdsManagement/AdDetails.vue"),
         meta: {
           breadcrumb: [

              { title: "", url: "/AdsManagement/AdDetails", active: true },
              { title: "AdDetails", active: true }
            ],
            rule: "anonymous",
            pageTitle: "AdDetails"
          }
        },
        // {
        //   path: "/AcceptRequests/",
        //   name: "AcceptRequests",
        //   component: () => import("./views/AdsManagement/AcceptRequests.vue"),
        //  meta: {
        //    breadcrumb: [

        //       { title: "", url: "/AdsManagement/AcceptRequests", active: true },
        //       {  active: true }
        //     ],
        //     rule: "anonymous",
        //     pageTitle: "Registrationrequests"
        //   }
        // },
        {
          path: "/Settings/SearchKeyWords/SearchKeyWords",
          name: "SearchKeyWords",
          component: () => import("./views/Settings/SearchKeyWords/SearchKeyWords.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/SearchKeyWords/SearchKeyWords", active: true },
              { title: "SearchKeyWords", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Settings/MobileSliders/MobileSliders",
          name: "SubscriptionPeriod",
          component: () => import("./views/Settings/MobileSliders/MobileSliders.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/MobileSliders/MobileSliders", active: true },
              { title: "MobileSliders", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Settings/AdsCatgeory",
          name: "AdsCatgeory",
          component: () => import("./views/Settings/AdsCatgeory/AdsCatgeory.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/AdsCatgeory", active: true },
              { title: "AdsCatgeory", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Settings/Subscriptions/Packages",
          name: "Packages",
          component: () => import("./views/Settings/Subscriptions/Packages.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/Subscriptions/Packages", active: true },
              { title: "Packages", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/CustomerAds/AdsDetails/:Id",
          name: "AdsDetails",
          component: () => import("./views/CustomerAds/AdsDetails.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/CustomerAds/AdsDetails", active: true },
              { title: "AdsDetails", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/BrokerReport",
          name: "BrokerReport",
          component: () => import("./views/Reports/BrokerReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/BrokerReport", active: true },
              { title: "Broker Report", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/ServiceProviderReport",
          name: "ServiceProviderReport",
          component: () => import("./views/Reports/ServiceProviderReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/ServiceProviderReport", active: true },
              { title: "ServiceProviderReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/EmployeeReport",
          name: "EmployeeReport",
          component: () => import("./views/Reports/EmployeeReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/EmployeeReport", active: true },
              { title: "EmployeeReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/CustomerReport",
          name: "CustomerReport",
          component: () => import("./views/Reports/CustomerReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/CustomerReport", active: true },
              { title: "CustomerReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/CustomerAdsReport",
          name: "CustomerAdsReport",
          component: () => import("./views/Reports/CustomerAdsReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/CustomerAdsReport", active: true },
              { title: "CustomerAdsReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/ServiceProviderSubscriptionReport",
          name: "ServiceProviderSubscriptionReport",
          component: () => import("./views/Reports/ServiceProviderSubscriptionReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/ServiceProviderSubscriptionsReport", active: true },
              { title: "ServiceProviderSubscriptionReport", active: true }
            ],
            rule: "anonymous"
          }
        },

        {
          path: "/Reports/BrokerSubscriptionReport",
          name: "BrokerSubscriptionReport",
          component: () => import("./views/Reports/BrokerSubscriptionReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/BrokerSubscriptionReport", active: true },
              { title: "BrokerSubscriptionReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/AcceptedOffersReport",
          name: "AcceptedOffersReport",
          component: () => import("./views/Reports/AcceptedOffersReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/AcceptedOffersReport", active: true },
              { title: "AcceptedOffersReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Reports/NotAcceptedOffersReport",
          name: "NotAcceptedOffersReport",
          component: () => import("./views/Reports/NotAcceptedOffersReport.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Reports/NotAcceptedOffersReport", active: true },
              { title: "NotAcceptedOffersReport", active: true }
            ],
            rule: "anonymous"
          }
        },
        //property services management submenus router
        {
          path: "/Settings/RealEstateServicesManagement/ServicesManagement",
          name: "ServicesManagement",
          component: () => import("./views/Settings/RealEstateServicesManagement/ServicesManagement.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/RealEstateServicesManagement/ServicesManagement", active: true },
              { title: "ServicesManagement", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/Settings/RealEstateServicesManagement/ServiceTypeManagement",
          name: "ServiceTypeManagement",
          component: () => import("./views/Settings/RealEstateServicesManagement/ServiceTypeManagement.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/RealEstateServicesManagement/ServiceTypeManagement", active: true },
              { title: "ServiceTypeManagement", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/ServiceRequests/ServiceProviderOffers",
          name: "ServiceProviderOffers",
          component: () => import("./views/ServiceRequests/ServiceProviderOffers.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/ServiceRequests/ServiceProviderOffers", active: true },
              { title: "ServiceProviderOffers", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/ServiceRequests/OffersAccepted",
          name: "OffersAccepted",
          component: () => import("./views/ServiceRequests/OffersAccepted.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/ServiceRequests/OffersAccepted", active: true },
              { title: "OffersAccepted", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/ServiceRequests/ServiceRequests",
          name: "ServiceRequests",
          component: () => import("./views/ServiceRequests/ServiceRequests.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/ServiceRequests/ServiceRequests", active: true },
              { title: "ServiceRequests", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/TermsAndConditions/TermsAndCondition",
          name: "TermsAndCondition",
          component: () => import("./views/TermsAndConditions/TermsAndCondition.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/TermsAndConditions/TermsAndCondition", active: true },
              { title: "TermsAndCondition", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/PackagesAndSubscriptions/BrokerPackage",
          name: "BrokerPackage",
          component: () => import("./views/PackagesAndSubscriptions/BrokerPackage.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/PackagesAndSubscriptions/BrokerPackage", active: true },
              { title: "BrokerPackage", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/PackagesAndSubscriptions/ServiceProviderSubscription",
          name: "ServiceProviderSubscription",
          component: () => import("./views/PackagesAndSubscriptions/ServiceProviderSubscription.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/PackagesAndSubscriptions/ServiceProviderSubscription", active: true },
              { title: "ServiceProviderSubscription", active: true }
            ],
            rule: "anonymous"
          }
        },
        {
          path: "/ServiceRequests/OffersNotAccepted",
          name: "OffersAccepted",
          component: () => import("./views/ServiceRequests/OffersNotAccepted.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/ServiceRequests/OffersNotAccepted", active: true },
              { title: "OffersNotAccepted", active: true }
            ],
            rule: "anonymous"
          }
        },
        // {
        //   path: "/RealEstateServicesManagement/propertyevaluationrequests",
        //   name: "propertyevaluationrequests",
        //   component: () => import("./views/RealEstateServicesManagement/propertyevaluationrequests.vue"),
        //   meta: {
        //     breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/propertyevaluationrequests", active: true },
        //       { title: "propertyevaluationrequests", active: true }
        //     ],
        //     rule: "anonymous"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/RealEstateDevelopment",
        //   name: "RealEstate Development",
        //   component: () => import("./views/RealEstateServicesManagement/RealEstateDevelopment.vue"),
        //   meta: {
        //     breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/RealEstateDevelopment", active: true },
        //       { title: "RealEstateDevelopment", active: true }
        //     ],
        //     rule: "anonymous"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/RealEstateContributions",
        //   name: "RealEstateContributions",
        //   component: () => import("./views/RealEstateServicesManagement/RealEstateContributions.vue"),
        //   meta: {
        //     breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/RealEstateContributions", active: true },
        //       { title: "RealEstateContributions", active: true }
        //     ],
        //     rule: "anonymous"
        //   }
        // },
        // {
        //   path: "/RealEstateServicesManagement/SpecialRequests",
        //   name: "SpecialRequests",
        //   component: () => import("./views/RealEstateServicesManagement/SpecialRequests.vue"),
        //   meta: {
        //     breadcrumb: [

        //       { title: "", url: "/RealEstateServicesManagement/SpecialRequests", active: true },
        //       { title: "SpecialRequests", active: true }
        //     ],
        //     rule: "anonymous"
        //   }
        // },

        {
          path: "/PaymentTypes/PaymentType",
          name: "PaymnetType",
          component: () => import("./views/PaymentTypes/PaymentType.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/PaymentTypes/PaymentType", active: true },
              { title: "AddPaymentType", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/PaymentTypes/LoadingFinancialDues",
          name: "LoadingFinancialDues",
          component: () => import("./views/PaymentTypes/LoadingFinancialDues.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/PaymentTypes/LoadingFinancialDues", active: true },
              { title: "LoadingFinancialDues", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/PaymentTypes/AddLoadingFinancialDues",
          name: "AddLoadingFinancialDues",
          component: () => import("./views/PaymentTypes/AddLoadingFinancialDues.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/PaymentTypes/AddLoadingFinancialDues", active: true },
              { title: "LoadingExpenses", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting",
          name: "Setting",
          component: () => import("./views/Settings/Settings.vue"),
          meta: {
            rule: "anonymous",
          }
        },
        {
          path: "/Setting/UnitType",
          name: "UnitType",
          component: () => import("./views/Settings/UnitType/UnitType.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/UnitType", active: true },
              { title: "UnitType", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/ServiceType",
          name: "ServiceType",
          component: () => import("./views/Settings/ServiceType/ServiceType.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/ServiceType", active: true },
              { title: "ServiceType", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/OwnerType",
          name: "OwnerType",
          component: () => import("./views/Settings/OwnerType/OwnerType.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/OwnerType", active: true },
              { title: "OwnerType", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/DeliveryStatus",
          name: "DeliveryStatus",
          component: () => import("./views/Settings/DeliveryStatus/DeliveryStatus.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/DeliveryStatus", active: true },
              { title: "DeliveryStatus", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/FinancialPosition",
          name: "FinancialPosition",
          component: () => import("./views/Settings/FinancialPosition/FinancialPosition.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/FinancialPosition", active: true },
              { title: "FinancialPosition", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/UnitModel",
          name: "UnitModel",
          component: () => import("./views/Settings/UnitModel/UnitModel.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/UnitModel", active: true },
              { title: "UnitModel", active: true }
            ],
            rule: "anonymous",
          },
        },

        {
          path: "/Setting/ConstructionUnitStatus",
          name: "ConstructionUnitStatus",
          component: () => import("./views/Settings/ConstructionUnitStatus/ConstructionUnitStatus.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/ConstructionUnitStatus", active: true },
              { title: "ConstructionUnitStatus", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/MobileSliders",
          name: "MobileSliders",
          component: () => import("./views/Settings/MobileSliders/MobileSliders.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/MobileSliders", active: true },
              { title: "Mobile Sliders", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/HotLine",
          name: "HotLine",
          component: () => import("./views/Settings/HotLine/HotLine.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/HotLine", active: true },
              { title: "HotLine", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/InternalRegulation",
          name: "InternalRegulation",
          component: () => import("./views/Settings/InternalRegulation/InternalRegulation.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/InternalRegulation", active: true },
              { title: "InternalRegulation", active: true }
            ],
            rule: "anonymous",
          },
        },
        {
          path: "/Setting/AddASecurity",
          name: "AddASecurity",
          component: () => import("./views/Settings/AddASecurity/Register.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Settings/AddASecurity", active: true },
              { title: "AddASecurity", active: true }
            ],
            rule: "anonymous",
          },
        },

        {
          path: "/Employee/Employee",
          name: "Employee",
          component: () => import("./views/Employee/Employee.vue"),
          meta: {
            breadcrumb: [

              { title: "", url: "/Employee", active: true },
              { title: "Employee", active: true }
            ],
            rule: "anonymous",
          },
        },


      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
         meta: {
            rule: "anonymous",
            pageTitle: "Login"
          }
        },

        {
          path: "/pages/PrivacyTerms",
          name: "Privacy Terms",
          component: () => import("@/views/pages/PrivacyTerms.vue"),
         meta: {
            rule: "anonymous",

          }
        },

        //NoticQRCode
        {
          path: "/NoticQRCode/:ID?",
          name: "NoticQRCode",
          component: () => import("./views/NoticQRCode/NoticQRCode.vue"),
         meta: {
            // breadcrumb: [

            //  { title: "", url: "/NoticQRCode/NoticQRCode", active: true },
            //    { title: "NoticQRCode", active: true }
            //  ],
            rule: "anonymous",
            pageTitle: "NoticQRCode"
          }
        },
        {
          path: "/register",
          name: "register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            breadcrumb: [
              { title: "", url: "/register", active: true },
              { title: "register", active: true }
            ],
            rule: "anonymous"
          }
        },

        {
          path: "/pages/ForgotPassword",
          name: "ForgotPassword",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "anonymous",
            pageTitle: "Forgot Password"
          }
        },
        {
          path: "/pages/forgot-password",
          name: "page-forgot-password",
          component: () =>
              import ("@/views/pages/ForgotPassword.vue"),
          meta: {
              rule: "anonymous",
              pageTitle: "Forgot Password "
          }
      },
      {
        path: "/pages/Facebook",
        name: "Facebook",
        component: () =>
            import ("@/views/pages/Facebook.vue"),
        meta: {
            rule: "anonymous",
            pageTitle: "Facebook"
        }
    },
        {
          path: "/pages/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "anonymous",

          }
        },
        {
          path: "/ChangePassword",
          name: "ChangePassword",
          component: () => import("@/views/pages/ChangePassword.vue"),
          meta: {
            rule: "anonymous"
          }
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "User"
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});


 router.beforeEach((to, from, next) => {
debugger;
   const publicPages = ["/pages/login", "/pages/ForgotPassword" ,"/pages/Facebook", "/pages/reset-password","/Settings/Privacy","/register"];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = localStorage.getItem("userInfo");

//   debugger;
//   // redirect to login page

   if (authRequired && !loggedIn) {
//    store.commit("REMOVE_USER_STORE", null, { root: true });
    if (to.path !== "/pages/login" ) {
      next("/pages/login");
    }
    else {
      next(); // Prevent infinite loop
    }
  } else {
    next();
  }


 });

// router.beforeEach((to, from, next) => {
//   // firebase.auth().onAuthStateChanged(() => {

//   //     // get firebase current user
//   //     const firebaseCurrentUser = firebase.auth().currentUser

//   // if (
//   //     to.path === "/pages/login" ||
//   //     to.path === "/pages/forgot-password" ||
//   //     to.path === "/pages/error-404" ||
//   //     to.path === "/pages/error-500" ||
//   //     to.path === "/pages/register" ||
//   //     to.path === "/callback" ||
//   //     to.path === "/pages/comingsoon" ||
//   //     (auth.isAuthenticated() || firebaseCurrentUser)
//   // ) {
//   //     return next();
//   // }

//   // If auth required, check login. If login fails redirect to login page

//   // if (to.meta.authRequired) {
//   //     if (!auth.isAuthenticated()) {
//   //         router.push({ path: "/pages/login", query: { to: to.path } });
//   //     }
//   // }

//   return next();
//   // Specify the current path as the customState parameter, meaning it
//   // will be returned to the application after auth
//   // auth.login({ target: to.path });

//   //});
// });

export default router;
