import axios from "../../../axios/index.js";
import store from "../../../../store/store.js";
//import router from "@/router";
// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    // debugger;
    if (
      (localStorage.getItem("token") == null ||
      localStorage.getItem("token") == undefined) && (window.location.hash!="#/LiveSessions" && window.location.hash!="#/LiveSessionAttandance")
    )
    // {
    //   debugger
    //   store.dispatch("auth/logout");
    //   router.push("/pages/login").catch(() => {});
    // }
    store.commit("auth/SET_BEARER", localStorage.getItem("token"));
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store.dispatch("auth/fetchAccessToken").then(access_token => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            });
          }

          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = "Bearer " + access_token;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  },
  login(email, pwd, Language) {
    debugger;
    var request={
      UserName: email,
      Password: pwd,
      UserLanguage: Language,
      UserType:'SuperAdmin',  // super Admin only login in AdminPanel
      FireBaseToken:'',
      PhoneNumber:''
    };
    return axios.post("/API/Auth/AdminLogin", request);
  },
  ChangeUserLanguage(languageId) {
    return axios.get("/Account/ChangeUserLanguage?languageID=" + languageId);
  },
  registerUser(confPassword, email, pwd) {
    return axios.post("/register", {
      confirmPassword: confPassword,
      email: email,
      password: pwd
    });
  },
   refreshToken() {
  return axios.post("/refresh", {refreshToken: localStorage.getItem("refreshToken")})
   },
};
