// en, de, fr, pt
export default {
  en: {
    MessageNote:"To send to a specific owner, please choose from the list. To send to residents of a specific form, please choose from the list of forms. Type of form. To send to the entire village, please do not choose.",
    title: "Title",
    AdTitle : "Ad Title",
    body:"Body",
    NoticQRCode:"Notic QR-Code",
    NotExit:"Not Exit",
    OffersAccepted:"Accepted Offers",
    IsOther: "Other Service",
    PushAllNotification:"Push All Notification",
    Areyousureyouwanttoaccepttheisrequest: "Are you sure that you want to accept this request?",
    UintManagmentAddOrEdit:"Add Uint Managment",
    UnitModel:"Unit Model",
    Subscription:"Subscription",
    Location:"Location",
    TermAR:"Arabic Content",
    TermEN:"English Content",
    UNumberOfUint:"Number Of Uint",
    CustomerProperty:"Customer Property",
    OwnerType:"Owner Type",
    Country:"Country",
    evaluationrequestsdetails:"Evaluation Requests Details",
    RealEstateServicesManagement:"Real Estate Services Management",
    Notifications:"Notifications",
    VisitAndRentNotices:"Visit And Rent Notices",
    CustomersService:"Customers Service",
    ServiceStatus: "Service Status",
    ServiceType: "Service Type",
    serviceDate: "service Date",
    serviceTime: "service Time",
    executionTieme: "execution Tieme",
    completed: "Completed",
    inprocess: "In process",
    pending: "Pending",
    WorksScreen:"Works Screen",
    DescriptionOfAttachment: "Description Of Attachment",
    LoginAttempt: "Login Attempt",
    Youarealreadyloggedin: "You are already logged in!",
    ProjectTitle: "Coronado Compound",
    AddNew: "Add New",
    Action: "Action",
    NameAr: "Arabic Name",
    NameEn: "English Name",
    CommingMeetings: "Comming Meetings",
    ClassName: "Class Name",
    CreatorName: "Creator Name",
    FixedExam: "Fixed Exam",
    RandomExam: "Random Exam",
    PleaseSelectAbsenceStudents: "Please Select On Absence Students",
    TotalAttendees: "Total Attendees",
    TotalAbsence: "Total Absence",
    MeetingsList: "Meetings List",
    IsPublic: "Is Public",
    HasRecord: "Has Record",
    NoImg:"No Image",
    StudentMeetingsList: "Student Meetings List",
    showNoDataAvailable: "No data available... Please try again",
    NoNotesAvailable: "No notes available",
    SorryYouAccountisNotActive: "Sorry..You account is not active",
    WelcomeMsg: "Welcome back, Please login to your account.",
   // Update: "Update",
    showError: "Something went wrong... Please try again",
    showUpdate: "Record updated successfully...",
    showCheckEmail: "Kindly check you email for new password...",
    showPasswordChanged: "Password changed...",
    NameFr: "French Name",
    AddressAr: "Arabic Adress",
    AddressEn: "English Adress",
    AddressFr: "French Adress",
    NoteAr: "Arabic Note",
    NoteEn: "English Note",
    NoteFr: "French Note",
    AboutUsAr: "Arabic AboutUs",
    AboutUsEn: "English AboutUs",
    AboutUsFr: "French AboutUs",
    Logo: "Logo",
    properties:"properties",

    SendVertificationCode: "Send Vertification Code",
    Cancel: "Cancel",
    Back: "Back",
    //Delete: "Delete",
    PhoneNumber: "Phone Number",
    VertificationCode: "Vertification Code",
    Vertify: "Vertify",
    MainInfo: "Main Info",
    LoginInfo: "Login Info",
    Save: "Save",
    Email: "Email",
    Password: "Password",
    Bus: "Bus",
    Buses: "Buses",
    Attachment: "Attachment",
    Installments: "Installments",
    Installment: "Installment",
    Stage: "Stage",
    StageYear: "Stage Year",
    Class: "Class",
    Age: "Age",
    Subjects: "Subjects",
    NewAttachment: "New Attachment",
    AddAttachment: "Add Attachment",
    Description: "Description",
    URL: "URL",
    File: "File",
    NewBus: "New Bus",
    AddBus: "Add Bus",
    ModelAr: "Arabic Model",
    ModelEn: "English Model",
    ModelFr: "French Model",
    IsActive: "Active",
    AddInstallment: "Add Installment",
    NewInstallment: "New Installment",
    InstallmentTypeEn: "Installment Type English NAme",
    PayDate: "Pay Date",
    IsPaid: "Paid",

    Routes: "Routes",
    Users: "Users",
    Students: "Students",
    Teachers: "Teachers",
    Drivers: "Drivers",
    StudentExam: "Student Test",
    StudentExamAnswer: "Student Test Answer",
    ExaminationManagement: "Examination Management",
    QuestionBank: "Question Bank",
    ExamRule: "Test Rule",
    ExamGeneration: "Test Generation",
    Setting: "Setting",
    Date: "Date",
    ClassSessionName: "Session",
    SubjectName: "Subject",
    FromDate: "From",
    ToDate: "To",
    ClassSession: "Session",
    ClassSchedule: "Class Schedule",
    ClassScheduleAddEdit: "Class Schedule Add/Edit",
    Status: "Status",
    ChangeStatusAd : "Are you sure you want to change the status of the ad?",
    SchoolInfo: "School Info",
    AssessmentType: "Assessment Type",
    BusSuperVisor: "Bus SuperVisor",
    Language: "Language",
    GradeType: "Grade Type",
    ExamType: "Test Type",
    QuestionLevel: "Question Level",
    QuestionType: "Question Type",
    Stages: "Stages",
    StageYears: "Stage Years",
    InstallmentType: "Installment Type",
    Name: "Name",
    Loading:"Loading...",
    StartDate:"Start Date",
    Reset: "Reset",
    ClearDates:"Clear Dates",
    SubscriptionStatus:"Subscription status",
    UserWithSamePhoneNumberExist: "User With Same Phone Number Exist",
    SomethingWentWrong: "Something Went Wrong",
    CodeIsNotExist: "Code Is Not Exist",
    Welcome: "Welcome",
    Modules: "Modules",
    Newlanguage: "New language",
    AddLanguage: "Add Language",
    NewModule: "New Module",
    AddModule: "Add Module",
    Module: "Module",
    Route: "Route",
    Details: "Details",
    AddRouteDetails: "Add Route Details",
    Longitude: "Longitude",
    Latitude: "Latitude",
    Region: "Capital",
    AddCapital:"Add New Governate",
    upCapital:"Update existing Governate",
    AddCity:"Add New City",
    upCity:"Update existing City",
    AddsearchK:"Add New Search Keyword",
    upsearchK:"Update existing Search Keyword",
    AddCat:"Add New Category",
    upCat:"Update  existing Category",
    NewRouteDetails: "New Route Details",
    Model: "Model",
    BusSupervisor: "Bus supervisor",
    Driver: "Driver",
    DriverAddEdit: "Driver Add/Edit",
    AddDriver: "Add Driver",
    NewDriver: "New Driver",
    Classes: "Classes",
    NewSubject: "New Subject",
    Subject: "Subject",
    Teacher: "Teacher",
    NewClass: "New Class",
    TeacherAddEdit: "Teacher Add/Edit",
    UserRole:"User Role",
    SubjectMaxScore: "Subject Max Score",
    AddSubject: "Add Subject",
    QuestionNum: "Question Number",
    QuestionAr: "Arabic Question",
    QuestionEn: "English Question",
    QuestionFr: "French Question",
    NewQuestion: "New Question",
    Question: "Question",
    Answers: "Answers",
    AnswerAr: "Arabic Answer",
    AnswerEn: "Englisg Answer",
    AnswerFr: "French Answer",
    IsRight: "IsRight",
    Addanswer: "Add Answer",
    DescriptionAr: "Arabic Description",
    DescriptionEn: "English Description",
    DescriptionFr: "French Description",
    Download: "Download",
    Type: "Type",
    AddRule: "Add Rule",
    NewRule: "New Rule",
    RuleInfo: "Rule Info",
    ExamStartDate: "Start Date",
    Exams: "Tests",
    TimeOfExamInMinutes: "Test Time In Minutes",
    Generate: "Generate",
    ExamMainInfo: "Test Main Info",
    GenerateQuestions: "Generate Questions",
    Text: "Text",
    TrueFalse: "True/False",
    MultiChoose: "Multi Choose",
    TimeInMinute: "Time In Minute",
    Weight: "Weight",
    TypeAr: "Arabic Type",
    TypeEn: "English Type",
    TypeFr: "French Type",
    UPDATE: "Update",
    Amount: "Amount",
    InstallmentDate: "Installment Date",
    DeadLine: "DeadLine",
    LevelAr: "Arabic Level",
    LevelEn: "English Level",
    LevelFr: "French Level",
    EventType: "Event Type",
    Events: "Events",
    SchoolArchitecture: "School Architecture",
    UserType: "User Type",
    RelatedAccounts: "Related Accounts",
    UserManagment: "User Managment",
    SubjectManagment: "Subject Managment",
    StageYearSubjects: "Stage Year Subjects",
    SubjectModules: "Subject Modules",
    BuseManagment: "Bus Managment",
    EventManagment: "Event Managment",
    InstallmentManagment: "Installment Managment",
    InstallmentYearStartDate: "Installment Year Start Date",
    InstallmentYearEndDate: "Installment Year End Date",
    Grade: "Grade",
    Active: "Active",
    Inactive: "Inactive",
    AddVisitOrRent: "Visit Or Rent Details",
    True: "True",
    False: "False",
    CustomUser: "Custom User",
    AllDriver: "All Driver",
    AllTeacher: "All Teacher",
    AllParent: "All Parent",
    BannerAds:"BannerAds",
    StudentTeacherInstallments: "Student/Teacher Installments",
    YouAreAboutToAddThisAssessment: "You are about to add this assessment",
    ThisModuleAlreadyExists: "This module already exists",
    ThisSubjectAlreadyExists: "This subject already exists",
    ThisDriverAlreadyExists: "This driver already exists",
    Areyousureyouwantdeletethisrecord: "Are you sure you want delete this record",
    User: "User",
    Success: "Success",
    CreatedSuccessfully: "Created Successfully",
    UpdatedSuccessfully: "Updated Successfully",
    Error: "Error",
    Downloading:"Downloading...",
    BrokerReport:"Broker Report",
    ServiceProviderReport:"ServiceProvider Report",
    CreatedFailed: "Created Failed",
    UpdatedFailed: "Updated Failed",
    DeletedSuccessfully: "Deleted Successfully",
    DeleteFailed: "Delete Failed",
    ServiceProviderSubscriptionsReport:"ServiceProvider Subscriptions Report",
    Confirm: "Confirm",
    DeleteConfirmText: "Are you sure you want delete this record?",
    ErrorTryLater: "SomeThing went wrong , please try later ..",
    AreyousureyouwantActivethisStudent: "Are you sure you want Active this Student?",
    Dashboard: "Dashboard",
    eCommerce: "eCommerce",
    Apps: "Apps",
    DownloadAsExcel:"Download as Excel...",
    ViewReport:"View Report",
    Todo: "Todo",
    Chat: "Chat",
    Calendar: "Calendar",
    FullCalendar: "Full Calendar",
    SimpleCalendar: "Simple Calendar",
    Shop: "Shop",
    Cart: "Cart",
    WishList: "Wish List",
    Checkout: "Checkout",
    UI: "UI",
    DataList: "Data List",
    ListView: "List View",
    ThumbView: "Thumb View",
    Grid: "Grid",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Colors",
    MobileSliders:"Splash",
    Card: "Card",
    Basic: "Basic",
    Statistics: "Statistics",
    Analytics: "Analytics",
    CardActions: "Card Actions",
    FiledComplaint:"Filed a Complaint",
    CardColors: "Card Colors",
    Table: "Table",
    agGridTable: "agGrid Table",
    AdsManagement:"AdsManagement ",
    AdType:"AdType",
    Components: "Components",
    Alert: "Alert",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Button",
    ButtonGroup: "Button Group",
    Chip: "Chip",
    Collapse: "Collapse",
    Dialogs: "Dialogs",
    Divider: "Divider",
    DropDown: "DropDown",
    List: "List",
   // Loading: "Loading",
    Navbar: "Navbar",
    Pagination: "Pagination",
    Popup: "Popup",
    Progress: "Progress",
    Sidebar: "Sidebar",
    Slider: "Slider",
    Tabs: "Tabs",
    Tooltip: "Tooltip",
    Upload: "Upload",
    FormsAndTable: "Forms & Table",
    FormElements: "Form Elements",
    Select: "Select",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    NumberInput: "Number Input",
    Textarea: "Textarea",
    FormLayouts: "Form Layouts",
    FormWizard: "Form Wizard",
    FormValidation: "Form Validation",
    FormInputGroup: "Form Input Group",
    Pages: "Pages",
    Authentication: "Authentication",
    Login: "Login",
    ServiceName:"ServiceName",
    Register: "Register",
    FillTheBelowFormToCreateANewAccount:"Fill The Below Form To Create A New Account",
    ForgotPassword: "Forgot Password",
    ResetPassword: "Reset Password",
    ChangePassword: "Reset Password",
    Url:"Url",
    LockScreen: "Lock Screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming Soon",
    404: "404",
    500: "500",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Profile: "Profile",
    Invoice: "Invoice",
    FAQ: "FAQ",
    Search: "Search",
    KnowledgeBase: "Knowledge Base",
    ChartsAndMaps: "Charts & Maps",
    Charts: "Charts",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Map",
    Extensions: "Extensions",
    QuillEditor: "Quill Editor",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access Control",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context Menu",
    StarRatings: "Star Ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    Import: "Import",
    Export: "Export",
    BrokerEdit:"Broker Edit",
    ExportSelected: "Export Selected",
    Others: "Others",
    MenuLevels: "Menu Levels",
    MenuLevel2p1: "Menu Level 2.1",
    MenuLevel2p2: "Menu Level 2.2",
    MenuLevel3p1: "Menu Level 3.1",
    MenuLevel3p2: "Menu Level 3.2",
    DisabledMenu: "Disabled Menu",
    Support: "Support",
    UserManagmentAddOrEdit:"User Managment Add Or Edit",
    Documentation: "Documentation",
    RaiseSupport: "Raise Support",
    demoTitle: "Card Title",
    demoText: "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
    /* custom i18n data */
    Course: "Course",
    CourseCatalog: "Course catalog",
    Instructor: "Instructors",
    Planning: "Planning",
    AnnualPlan: "Annual Plan",
    Facility: "Facility",
    ClassRoom: "Class Room",
    CourseType: "Course Type",
    CourseLevel: "Course Level",
    RealEstateServicesrequests: "RealEstateServicesrequests",
    "Servicesrequests":"Services Requests",
    "Comments&Rates":"Comments&Rates",
    MaterialType: "Material Type",
    ViewMaterial: "View Material",
    PopulationList: "Population List",
    IndustrySector: "Industry Sector",
    AreaOfStudy: "Area Of Study",
    BrokerSubscriptionReport:"Broker Subscription Report",
    CourseCategory: "Course Category",
    Nationality: "Nationality",
    MaritalStatus: "Marital Status",
    ContactType: "Contact Type",
    EducationDegree: "Education Degree",
    Customer: "Customer",
    Trainee: "Trainee",
    UserManagement: "User Management",
    Home: "Home",
    NotAcceptedOffersReport:"Not Accepted Offers Report",
    CustomerPropertyReport:"Customer Property Report",
    YearSummary: "Year Summary",
    BrokerInformation:"Broker Information",
    CourseClass: "Course Classes",
    QuestionManagement: "Question Management",
    Reports: "Reports",
    CourseRegistration: "Course Registrations",
    CourseRequest: "Course Requests",
    EnrollmentManagement: "Enrollment Management",
    TestManagement: "Test Management",
    CourseExam: "Course Exams",
    QuestionCategory: "Question Category",
    TermsOfUse: "Terms Of Use",
    PrivacyPolicy: "Privacy Policy",
    Imprints: "Imprints",
    Category:"Category",
    CategoryAR:"Category Arabic Name",
    CategoryEN:"Category Engilish Name",
    FeaturedProperty:"Featured Property",
    FeaturedAds:"Featured Ads",
    RecommendedAds:"Recommended Ads",
    NewMaterial: "New Material",
    StudentUserInstallment: "Student/User Installment",
    BusTracking: "Bus Tracking",
    All: "All",
    CountryCode:"CountryCode",
    ChannelBook: "Channel Book",
    ChannelBookType: "Channel Book Type",
    RequestType:"RequestType",
    ChannelBookAddEdit: "Channel Book Add/Edit",
    Sender: "Sender",
    Recevier: "Recevier",
    Message: "Message",
    MessageType: "MessageType",
    IsReplyable: "Is Replyable",
    BusSuperVisors: "Bus Super Visors",
    EndDate: "End Date",
    AddSubjects: "Add Subjects",
    addPackage:"Add New Package",
    upPackage:"Update Existing Package",
    ClassTeacherSubject: "Class Teacher Subject",
    Edit: "Edit",
    NotificationWhithoutImage:"Notification Whithout Image",
    Previewtherequest:"Preview Request",
    ThisItemAlreadyExist: "This Item Already Exist",
    AlreadyExist: "Already Exist",
    Material: "Material",
    Logout: "Logout",
    Pdf: "Pdf",
    Video: "Video",
    UsersManagement: "Users Management",
    Image: "Image",
    Document: "Document",
    ExamCode: "Test Code",
    ShowAds:"Show Ads",
    UpdateImage: "Update Image",
    RemoveImage: "Remove Image",
    WelcomeBack: "Welcome back, please login to your account.",
    RememberMe: "Remember Me",
    SendChannelBook: "Send channel book",
    Data: "Data",
    RelaredAccounts: "Relared accounts ",
    Filters: "Filters",
    Accept: "Accepted",
    Rejected:"Rejected",
    StudentInstallmentCounts: "Student installment counts",
    ExamsAndEventsForWeek: "Tests and events for week",
    AllUsers: "All users",
    ExamsForWeek: "Tests for week",
    EventsForWeek: "Events for week",
    AlltInstallmentCounts: "All installment counts",
    PaidInstallmentCount: "Paid installment count",
    UnPaidInstallmentCount: "UnPaid installment count",
    AllChangeRouteRequest: "All change route request count",
    AllChangeRouteRequestPending: "change route request pending count",
    AllChangeRouteRequestRefused: "All change route request refused",
    AllChangeRouteRequestAccept: "All change route request accept",
    StudentBusOffForWeek: "Student bus off for week",
    studentBusOFF: "student number",
    AllRoutesAndBusses: "Routes and busses",
    StudentChangeRouteRequest: "StudentChangeRouteRequest",
    StudentChangeRouteRequestAddEdit: "Add/Edit",
    Accepted: "Accepted",
    AddEdit: "Add/Edit",
    Privacy:"Privacy",
    ColorCode: "Color Code",
    SubjectExamRole: "Subject Test Role",
    BusTrips: "Bus Trips",
    BusTrip: "Bus Trip",
    TIMEOFSTART: "Time of start",
    TIMEOFEND: "Time of end",
    PickupLocation: "Adress",
    isInTrip: "is in trip ?",
    AddP:"Add New Property",
    upP:"Update the exsiting property",
    AddPS:"Add new Property Specs",
    upPS:"Update the exsiting Property Specs",
    DaysOfWeek: "Day",
    DateFrom:"DateFrom",
    DateTo:"DateTo",
    CreatedDate:"CreatedDate",
    maxScore: "max score",
    NewRequest: "New Request",
    PasswordUpdated: "Password updated",
    SaveChanges: "Save Changes",
    YourPasswordHasBeenUpdatedSuccessfully: "Your Password Has Been Updated Successfully",
    Recoveryourpassword: "Recover your password",
    "Pleaseenteryouremailaddressandwe'llsendyouInstructionsOnHowtoresetyourpassword": "Please enter your email address and we'll send you Instructions On How to reset your password",
    BackToLogin: "Back To Login",
    sendcodetomail: "send code to phone",
    "pleasecheckyoure-mailanconfirmationsmssenttoyou": "please check your phone a confirmation sms sent to you",
    NomberOfQuestions: "Number Of Questions",
    SubjectStageYear: "Subject Stage Year",
    TeacherStudentsExamMarks: "Test Marks and Correct",
    ExamGrade: "Test Grade",
    AreyousureyouwanttoDenytheisrequest:"Are you sure you want to Deny theis request?",
    StudentGrade: "Student Grade",
    Assessment: "Students Assessment",
    TrueAndFalse: "True And False",
    ShowWithAnswers: "Correct Answers",
    NomperOfdays:"Number Of Days",
    IsDeny:"Access Denied",
    DenyReason : "Deny Reason",
    AdsDetails:"Property Details",
    IsAllow: "Allow Access",
    Reason:"Reason",
    For: "For",
    PackageNoDays:"Package Days Count",
    Performance: "Performance",
    OverAll: "Over All",
    Operation:"Operation",
    OverAllPerformance: "Over All Performance",
    FailedExam: "Failed Test(s)",
    NewAssessment: "New Assessment",
    OverAllGrade: "Over All Grade",
    AssessmentFor: "Assessment For",
    UnPaied: "UnPaied",
    Paied: "Paied",
    Employees:"Employees",
    OverDue: "OverDue",
    ParentStudentInstallment: "Installment",
    EndedExam: "Old Exam",
    NewExams: "New Exams",
    EndedAssignment: "Old Assignment",
    NewAssignments: "New Assignments",
    SubjectPerformance: "Subject Performance",
    Contacts: "Contacts",
    ContactNumber : "Contact Number",
    PleaseAllowNotification: "Please Allow Notification",
    SendMessage: "Send Message",
    Total: "Total",
    Color: "Color",
    IsExam: "A different exam for each student",
    CodePhoneNumberHint: "Please Enter Code Phone Number EX.(+2)",
    Roles: "Roles",
    RoleScreenPermission: "Group Admin Permissions",
    IsShow: "Is Show",
    ScreenName: "Screen Name",
    ScheduleAndEvents: "Schedule And Events",
    MarkAsReadAll: "Mark As Read All",
    New: "New",
    Add:"Add",
    Update:"Update",
    Delete:"Delete",
    Print:"Print",
    Index:"Index",
    close:"Close",
    AllStudents: "All Students",
    ActiveStudents: "Active Students",
    InActiveStudent: "InActive Students",
    StudentActiveLicenseCounts: "Student Active License Counts",
    ActiveLicense: "Active License",
    showChat: "show Chat",
    ClassSuperVisor: "Class Supervisor",
    ClassSuperVisors: "Class Supervisors",
    FullName: "Full Name",
    TypeNameEn: "Type Name In English",
    TypeNameAr: "Type Name In Arabic",
    Parents: "Parents",
    ParentLoginInfo: "Parent Login Info",
    HaveSisBro: "Have SisBro",
    ClassSuperVisorAddEdit: "ClassSuper Visor Add Edit",
    GroupOfAdmin: "Group Of Admin",
    SetStudentAbsence: "Set Student Absence",
    StudentsPerformance: "Students Performance",
    ShowPerformance: "Show Performance",
    RegionFr: "Region Fr",
    RegionAr: "Region Ar",
    RegionEn: "Region En",
    NotReplayableChat: "Not Replayable Chat",
    Schedule: "Schedule",
    LiveSessions: "Live Sessions",
    Assignment: "Assignment",
    AbsentsInThisMonth: "Absents in month",
    YourScheduleInThisSemester: "Your schedule in this semester",
    EventsInThisSemester: "Events in this semester",
    GoToLink: "Go to link",
    UserFavorit: "User Favorit",
    Comments: "Comments",
    LiksUsers: "Liks Users",
    RateUsers: "Rate Users",
    MainData: "Main Data",
    Videos: "Videos",
    VideoEdit: "Video Edit",
    ViewCount: "View Count",
    Comment: "Comment",
    RateAndNotes:"Rate And Notes",
    Rates: "Rates",
    Keyword: "Keyword",
  
    Expert: "Expert",
    TermsAndConditions:"Terms And Conditions",
    Offers:"Offers",
    Favorits: "Favorits",
    _ServiceRequests:"Service Requests",
    ServiceRequests:"Offers && Requests",
    Competitions: "Competitions",
    TotalRate: "Total Rate",
    GotoVideoDetails: "Goto Video Details",
    UserVideo: "User Videos",
    SearchKeyWords:"Search KeyWords",
    AppUserFavirotVideos: "Favirot Videos",
    WinnersVideosLastCompetition: "The Winners Videos in Last Competitions",
    Monthly: "Monthly",
    Weekly: "Weekly",
    appUsers: "appUsers",
    experts: "experts",
    videos: "videos",
    countOfVideosInThisWeek: "count Of Videos In This Week",
    countOfVideosInThisMonth: "count Of Videos In This Month",
    TopWeeklyVideo: "Top Weekly Videos",
    TopMonthlyVideo: "Top Monthly Videos",
    ExpertEdit: "Expert_Edit",
    Address: "Address",
    AppUser: "AppUser",
    AdsPublishStatus:"Ads Publish Status",
    News: "News",
    Stars:"Stars",
    NoAds:"No Ads published",
    Review:"Review",
    branchesList:"Branches List",
    ServiceProviderDetails:"Service Provider Details",
    NewsHeasline: "Arabic News Head line",
    NewsHeaslineEn: "English News Head line",
    NewsContentEn: "News English Content",
    NewsContentAr: "News Arrabic Content",
    NewsDate: "News Date",
    AuthorNameAr: "Author Arabic Name",
    AuthorNameEn: "Author English Name",
    AuthorName: "Author: ",
    MainImg: "Main Img",
    NewsGalleries: "News Galleries",
    EditNews: "Edit News",
    Example:"Example",
    City: "City",
    District: "District",
    Item: "Item",
    Submit: "Submit",
    VisitorData: "Vistor Or Renter Data",
    Visitor: "Visitor/Renter",
    AddOrEditAdsManagement:"Add Or Edit Owners Management",
    UnitNumber:"Unit Number",
    UintName:"Uint Name",
    UintOwner:"Uint Owner",
    UintPaymentStatus:"Uint Payment Status",
    UnitType:"Unit Type",
    MainCat:"Main Category",
    Descraption:"Descraption",
    ConstructionUnitStatus:"Construction Unit Status",
    DeliveryStatus:"Unit Delivery Status",
    ManagingRole:"Managing Role",
    SystemMangment:"System Mangment",
    Owner:"Unit Owner",
    PropertyManagementrequests:"Property Management Requests ",
    contributionsrequests:" Contributions Requests",
    developmentrequests:"Development Requests ",
    FinancialPosition:"Financial Position",
    ServicesManagement:"Services Management",
    IdentityNumber:"Identity Number",
    EntranceTime:"Entrance Time",
    ExitTime:"Exit Time",
    VistorCount:"Vistor Count",
    RentCount:"Rent Count",
    Rent: "Rent",
    request:"Request",
    VisitType:"Visit Type",
    Visit:"Visit",
    IdFront:"Id Front",
    IdBack:"Id Back",
    Banner1:"Banner1",
    Banner2:"Banner2",
    Banner3:"Banner3",
    Banner4:"Banner4",
    MoblieSlider:"Moblie Slider",
    HotLine: "HotLine",
    CustomerRequests:"Customer Requests",
    InternalRegulation: "Internal Regulation",
    OwnerUnits: "Owner Units",
    reportofunitsfinancialpostionstatus : "Report Of Units Financial Postion Status",
    ReportOfUnitsRecevied : "Report Of Units Recevied",
    //Print: "Print",
    Block: "Block",
    PropertyName:"Property Name",
    SubscriptionPeriod:"Subscription Period",
    Subscriptions:"Subscriptions",
    BranchName:"Branch Name",
    Deliverystatus: "Delivery Status",
    DeleteAd:"Delete Ad",
    Finished: "Finished",
    confirmdeleteAd:"Do you want to delete this Ad?",
    IdeNumber: "IdeNumber",
    buildingnumber: "Building number",
    Round: "Round",
    TermsAndCondition:"Terms And Condition",
    FeaturedAndRecomended:"Featured And Recomended Ads",
    Price:"Price",
    Mobile:"Mobile",
    AddASecurity: "Add A Security",
    UserInformation: "User Information",
    MaintenanceRequests: "Maintenance Requests",
    ServiceDetails: "Service Details",
    Rentersorvisitorshohaventleftet: "Renters Or Visitors Who Haven't Left Yet",
    OwnerInActiom: "Owner In Actiom",
    RentRequests:"Not Approved Rent Requests",
    VisitRequests: "Not Approved Visit Requests",
    Approve: "Approve",
    Reject: "Reject",
    Clint:"Clint",
    image:"Image",
    _Pages:"Pages",
    Actions:"Actions",
    refusedAds:"Refused Ads",
    underReviewAds:"Under Review Ads",
    currentUsers:"Current Users",
    Broker:"Broker",
    ServiceTypeManagement:"Service Type Management",
    RequestDate: "Request Date",
    buildingNumber: "Buildig Number",
    Registrationrequests: "Registration Requests",
    AddPaymentType: "Add Payment Type",

    PaymentTypes: "Payment Types",
    LoadingFinancialDues: "Loading Financial Dues",
    AddExpence: "Add Expence",
    Typeofreceivable: "Type Of Receivable",
    RealstateSearcher:"Realstate Searcher",
    LoadingExpenses: "Add Expenses",
    BranchLocation:"Branch Location",
    Map:"Map",
    ServiceProviderName:"Service Provider Name",
    Phone:"Phone",
    Offer:"Offers",
    Distance:"Distance",
    Request:"Request",
    OwnerDetails: "Owner Details",
    reasonofrefuse : "Reason of Refuse",
    OffersNotAccepted:"Offers Not Accepted",
    CustomerPackages:"Packages",
    ShowReceivables: "Show Receivables",
    PropertyManagement:"Property Management",
    Packages:"Packages",
    RealEstateDevelopment:"Real Estate Development",
    PackagesAndSubscriptions:"Packages And Subscriptions",
    RealEstateContributions:"Real Estate Contributions",
    SpecialRequests:"Special Requests",
    SubName:"Subscription Name",
    ServiceProviderSubscription:"Service Provider Subscription",
    RequestDetails:"RequestDetails",
    Requester:"Requester",
    PackgNameAr:"Arabic Packg Name",
    PackgNameEn:"English Packg Name",
    BrokerPackageType:"Broker Package Type",
    BrokerName:"Broker Name",
    UpdateServiceProvider:"Update ServiceProvider",
    UpdateBroker:"Update Broker",
    WatingUsers: "WatingUsers",
    Notes:"Notes",
    termsConditions:"I accept the terms & conditions.",
    NumperOfMounths:"Numper Of Mounths",
    ServiceProviderSubscriptions:"ServiceProvider Subscriptions",
    UserDetails: "User Details",
    EmployeeUserDetails: "Employee User Details",
    ClientUserDetails: "Client User Details",
    AdsCatgeory:"Ads Catgeory",
    AdDetails:"AdDetails",
    PropertySpecs:"PropertySpecs",
    TechnicalName: "TechnicalName",
    Call:"Call",
    WhatsAPP:"WhatsAPP",
    AcceptedOffersReports:"Accepted Offers Report",
    ServiceProviderOffers:"Service Provider Offers",
    CustomerName:"Customer Name",
    RealEstateAds: "Real Estate Ads",
   ServicesProvider:"Service Provider",
   CustomerReport:"Customer Report",
   ReplyWaitingNumperDay:"Reply Waiting Numper Day",
   ComplaintWaitingNumperDay:"Complaint Waiting Numper Day",
   ReplyDate:"Reply Date",
   ComplaintDate:"Complaint Date",
   EmployeeReport:"Employee Report",
   ComplaintImage:"Complaint Image",
   ComplaintText:"Complaint Text",
   Complaints:"Complaints",
   ReplyText:"Reply Text",
  },
  ar: {
    "Comments&Rates":"التقييم & التعليقات",
    ComplaintImage:"صوره الشكوي",
    Complaints:"الشكاوي",
    ComplaintText:"الشكوي",
    ReplyText:"الرد",
    CustomerReport:"تقارير العملاء",
    ComplaintDate:"وقت الشكوي",
    ReplyDate:"وقت الرد",
    PropertySpecs:"المواصفات",
    ComplaintWaitingNumperDay:"عدد ايام انتظار الشكوي",
    ReplyWaitingNumperDay:"عدد ايام انتظار الرد",
    EmployeeUserDetails: "تفاصيل الموظف",
    ClientUserDetails: "تفاصيل العميل",
    Downloading:"تحميل...",
    DownloadAsExcel:"تحميل اكسيل",
    refusedAds:"اعلانات مرفوضة",
    underReviewAds:"اعلانات قيد المراجعة",
    AdsCatgeory:"فئة الاعلانات",
MainCat:"الفئة الرئيسية",

    Distance:"المسافه",
    SubName:"اسم الباقه السنويه",
    ServiceProviderSubscription:"اشتراكات مقدمي الخدمات",
    NumperOfMounths:"عدد شهور الباقه",
    PackagesAndSubscriptions:"الباقات والاشتراكات",
    Notes:"الملاحظات",
    Action:"اجراءات",
    UpdateServiceProvider:"تعديل مقدم الخدمات",
    ServiceProviderOffers:"عروض مقدمين الخدمات",
    RealEstateAds: "إعلانات عقارية",
    ServicesProvider:"مقدم خدمات",
    CategoryAR:"اسم الفئة بالعربى",
    CategoryEN:"اسم الفئة بالانجيليزى",
    Package:"الباقات",
    CustomerPackages:"الباقات ",
    ServiceProviderName:"اسم مقدم الخدمات",
    CustomerName:"اسم طالب الخدمه",
    PackgNameAr:"اسم الباقة بالعربى",
    PackgNameEn:"اسم الباقة بالانجليزى",
    CustomerRequests:"طلبات العملاء",
    TechnicalName: "اسم الفني",
    SubscriptionPeriod:" الاشتراكات السنويه",
    UserDetails: "تفاصيل الباحث عن عقار",
    ServiceProviderSubscriptions:"اشتراكات مقدم الخدمات",
    RealstateSearcher: "الباحث عن عقار",
    WatingUsers: "مستخدين قيد الانتظار",
    OffersAccepted:"عروض تم الموافقه عليها",
    OffersNotAccepted:"عروض لم تتم الموافقه عليها",
    RequestDetails:"بيانات الطلب",
    Requester:"مقدم الطلب",
    NoAds:"لايوجد اعلانات",
    ShowReceivables: "إظهار المستحقات",
    reasonofrefuse:"سبب الرفض",
    OwnerDetails: "تفاصيل المالك",
    UpdateBroker:"تعديل مقدم عقارات",
    Mobile:"رقم هاتف 2",
    FeaturedProperty:"خاصية مميزة",
    LoadingExpenses: "إضافة مصروف",
    Price:"السعر",
    Typeofreceivable: "نوع المصروف",
    AddExpence: "تحميل المصروف",
    LoadingFinancialDues: "تحميل المستحقات المالية",
    PaymentTypes: "المستحقات المالية",
    AddPaymentType: "إضافة مستحقات مالية",
    FillTheBelowFormToCreateANewAccount:"املا الحقول الاتيه لانشاء حساب جديد",
    Offer:"عروض",
    termsConditions:"اوافق علي الشروط والاحكام.",
    Registrationrequests: "طلبات التسجيل لم يتم الموافقة عليها",
    buildingNumber: "رقم المبنى",
    RequestDate: "تاريخ الطلب",
    Banner4:"الاعلان الرابع",
    Banner3:"الاعلان الثالث",
    Banner2:" الاعلان الثاني",
    Banner1:"الاعلان الاول",

    Approve:"قبول الطلب",
    BrokerName:"اسم مقدم العقارات",
    BrokerPackageType:"نوع الباقه المستخدمه",
    Reject: "رفض الطلب",
    Privacy:"الخصوصيه",
    UserRole:"صلاحيات المستخدم",
    MobileSliders:"تعديل ايقونه الموقع",
    Stars:"عدد النجوم",
    ClearDates:"حذف التاريخ",
    Request:"طلبات خدمه",
    VisitRequests: "طلبات زيارة قيد التنفيذ",
    RentRequests:"طلبات إيجار قيد التنفيذ",
    OwnerInActiom: "ملاك قيد التفعيل",
    Map:"خريطه",
    currentUsers:"المستخدمين الحاليين",
    Phone:"رقم الهاتف",
    ServiceProviderDetails:"تفاصيل مقدم الخدمات ",
    BranchName:"اسم الفرع",
    ServicesManagement:"اداره الخدمات",
    BranchLocation:"عنوان الفرع",
    Rentersorvisitorshohaventleftet: "زائرين لم يغادروا بعد موعد الخروج",
    ServiceDetails: "تفاصيل الخدمة",
    AddsearchK:"اضافة كلمة بحث جديدة",
    upsearchK:"تعديل كلمة البحث الحالية",
    Region:"المحافظة",
    MaintenanceRequests: "طلبات الصيانة",
    UserInformation: "البيانات الاساسية",
    BrokerInformation:"بيانات مقدم العقار",
    SystemMangment:"اداره النظام",
    VisitorData:"بيانات الزائر / المؤجر",
    Nationality:"الجنسيه",
    AddASecurity: "إضافة فرد أمن",
    Round: "الدور",
    buildingnumber: "رقم المبنى / الشريحة",
    IdeNumber: "الرقم القومي",
    Finished: "انتهت",
    Deliverystatus: "حالة التسليم",
    Block: "حظر",
    AddCapital:"اضافة محافظة جديدة",
    upCapital:"تعديل المحافظة الحالية",
    AddCity:"اضافة مدينة جديدة",
    upCcity:"تعديل المدينة الحالية",
    AddP:"اضافة خاصية جديدة",
    upP:"تعديل الخاصية الحالية",
    AddPS:"اضافة مواصفة جديدة",
    upPS:"تعديل المواصفة الحالية",
    AddCat:"اضافة فئة جديدة",
    upCat:"تعديل الفئة الحالية",
    //Print: "طباعة",
    Back: "عوده الى الخلف",
    ReportOfUnitsRecevied : "تقرير الوحدات المستلمة",
    reportofunitsfinancialpostionstatus : "تقرير حالة التأخرات المالية",
    OwnerUnits: "وحدات المالك",
    InternalRegulation: "اللائحة الداخلية",
    MessageNote:"لارسال الى مالك محدد يرجي الاختيار من القائمة ولارسال الى ساكني نموذج محدد يرجي الاختيار من قائمة النماذج نوع النموذج لارسال للقرية كاملة يرجي عدم الاختيار",
    title:"العنوان",
    AdTitle:"عنوان الاعلان",
    body:"المحتوي",
    MoblieSlider: "رفع الصور",
    HotLine: "الخط الساخن",
    Subscriptions:"الاشتراكات",
    NoticQRCode:"مستأجر | زائر",
    NotExit:"غير متوفرة",
    IsOther: "خدمة اخري",
    IdBack:"صورة البطاقة (خلفي)",
    IdFront:"صورة البطاقة (امامي)",
    Visit:"زيارة",
    Rent:"ايجار",
    Owner:"مالك الوحدة",
    RentCount:"عدد المستاجرين",
    VistorCount:"عدد الزائرين",
    ExitTime:"ميعاد الخروج",
    EntranceTime:"ميعاد الدخول",
    ServiceTypeManagement:"اداره الخدمات الفرعيه",
    DateFrom:"التاريخ من",
    DateTo:"التاريخ الى",
    IdentityNumber:"الرقم القومي",
    FinancialPosition:"موقف الوحدة المالي",
    DeliveryStatus:"حالة تسليم الوحدة",
    ConstructionUnitStatus:"موقف بناء الوحدة ",
    Descraption:"الوصف",
    UnitType:"نوع الاعلان",
    _ServiceRequests:"طلبات الخدمات",
    ServiceType:"نوع الخدمة",
    properties:"خصائص الاعلانات",
    NoImg:"لا يوجد صورة",
    SubscriptionStatus:"حاله الاشتراك",
    ServiceRequests:"العروض و الطلبات",
    UintManagmentAddOrEdit:"اضافة وحدة",
    UintOwner:"مالك الوحدة",
    Broker:"عقاري",
    BrokerEdit:"تعديل بيانات مقدم العقار",
    UintPaymentStatus:"موقف الوحدة المالي",
    UintName:"أسم الوحدة",
    UnitNumber:"رقم الوحدة",
    AdsManagement:"اداره الاعلانات",
    AdditionalPhoneNumber:"رقم موبايل اخر",
    AddOrEditAdsManagement:"اضافة الملاك",
    UNumberOfUint:"الوحدات المملوكة",
    UnitModel:"نموذج الوحدة",
    OwnerType:"نوع الملكية",
    UsersManagement:"ادارة المستخدمين",
    "RealEstateServicesManagement":"ادارة الخدمات ",
    "RealEstateServicesrequests":"طلبات الخدمات العقارية",
    "Servicesrequests":"طلبات الخدمات",
    CustomersService:"خدمة العملاء",
    ServiceStatus: "حالة الطلب",
    serviceDate: "تاريخ الطلب",
    serviceTime: "وقت الطلب",
    executionTieme: "وقت التنفيذ",
    completed: "تم التفيذ",
    inprocess: "قيد التنفيذ",
    pending: "لم يتم التنفيذ",
    Notifications:"الاشعارات",
    VisitAndRentNotices:" اخطارات الزياره والايجار",
    WorksScreen:"شاشه الاشغال",
    Country: "الدولة",
    City: "المدينة",
    District: "الحي",
    Rejected:"تم الرفض",
    PackageNoDays:"عدد ايام الباقة",
    
    Submit: "اضافة",
    Item: "عنصر",
    News: "الاخبار",
    TermAR:"المحتوى بالعربية",
    TermEN:"المحتوى بالانجيليزية",
    evaluationrequestsdetails:"تفاصيل التقييم العقارى",
    AuthorName: "الكاتب",
    EditNews: "تعديل الخبر",
    Example:"مثال توضيحي",
    AdType:"نوع الاعلان",
    ViewReport:"عرض التقرير",
    NewsHeasline: "عنوان الخبر باللغة العربية",
    NewsHeaslineEn: "عنوان الخبر باللغة الانجليزية",
    NewsContentEn: "الخبر باللغة الانجليزية",
    NewsContentAr: "الخبر باللغة العربية",
    AuthorNameAr: "اسم الكاتب باللغة العربية",
    AuthorNameEn: "اسم الكاتب باللغة الانجليزية",
    NewsDate: "تاريخ النشر",
    Review:"التعليقات",
    MainImg: "الصورة الرئسيسة للخبر",
    NewsGalleries: "معرض الصور",
    AppUser: "المستخدم",
    Address: "العنوان",
    RateAndNotes:"التقيم والمراجعات",
    StartDate:"بدايه المده",
    ExpertEdit: "تعديل بيانات الخبير",
    UserVideo: "الفيديوهات",
    UserManagmentAddOrEdit:"تعديل بيانات المستخدم",
    AppUserFavirotVideos: "الفيديوهات المفضلة",
    TopMonthlyVideo: "اعلي فيديوهات خلال هذ الشهر",
    TopWeeklyVideo: "اعلي فيديوهات خلال هذ الاسبوع",
    appUsers: "المستخدمين",
    experts: "الخبراء",
    CreatedDate:"تاريخ التسجيل",
    videos: "الفيديوهات",
    ServiceName:"اسم الخدمة",
    countOfVideosInThisWeek: "عدد الفيديوهات الجديدة هذا الاسبوع",
    countOfVideosInThisMonth: "عدد الفيديوهات الجديدة هذا الشهر",
    Weekly: "اسبوعي",
    Monthly: "شهري",
    WinnersVideosLastCompetition: "الفديوهات الفائزة في المسابقات الاخيرة",

    GotoVideoDetails: "الذهاب الي تفاصيل الفيديو",
    TotalRate: "اجمالي التقييمات",
    Competitions: "المسابقات",
    Favorits: "الأعجابات",
    Expert: "خبير",
    TermsAndConditions:" الشروط والاحكام",
    TermsAndCondition:"الشروط والاحكام",
    Keyword: "كلمة دالة",
    Rates: "التقييمات",
    Comment: "التعليق",
    ViewCount: "عدد المشاهدات",
    Loading:"يتم التحميل...",
    VideoEdit: "تعديل  الفيديو",
    Videos: "الفيديوهات",
    Subscription:"الاشتراك",
    MainData: "البيانات الاساسية",
    RateUsers: "التقييمات",
    LiksUsers: "الاعجابات",
    Comments: "التعليقات",
    UserFavorit: "مفضل لدى",
    GoToLink: "الذهاب الي الرابط",
    EventsInThisSemester: "احداث هذا الفصل الدراسي",
    YourScheduleInThisSemester: " جدولك هذا الفصل الدراسي",
    AbsentsInThisMonth: "غياب الشهر",
    Assignment: "الواجبات",
    LiveSessions: "الحصص اللايف",
    Schedule: "جدول الحصص",
    NotReplayableChat: "دردشة غير معادة",
    RegionEn: "المنطقة بالانجليزي",
    RegionAr: "المنطقة بالعربي",
    RegionFr: "المنطقة بالفرنساوي",
    Visitor: "الزائر / المؤجر",
    VisitType:"نوع الزيارة",
    Offers:"عروض تم التقديم عليها",
    AddVisitOrRent:"تفاصيل طلب الزياره او الايجار",
    ShowPerformance: "التقييم",
    StudentsPerformance: "تقييم الطلاب",
    branchesList:"قائمه الفروع",
    NotificationWhithoutImage:"اشعار بدون صورة",
    SetStudentAbsence: "اخذ غياب الطلاب",
    GroupOfAdmin: "مجموعات المشرفين",
    ClassSuperVisorAddEdit: "اضافة وتعديل مشرف الفصل",
    HaveSisBro: "لديه اخ/اخت",
    ParentLoginInfo: "بيانات دخول ولي الامر",
    Parents: "اولياء الامور",
    AdsPublishStatus:"حالة نشر الاعلان",
    FullName: "الاسم الكامل",
    TypeNameEn: "نوع الملكية بالانجليزية",
    TypeNameAr: "نوع الملكية بالعربية",
    addPackage:"اضافة باقة جديدة",
    upPackage:"تعديل الباقة الحالية",
    ClassSuperVisors: "مشرفين غياب الفصول",
    ClassSuperVisor: "مشرف غياب الفصول",
    Reports: "التقارير",
    ActiveLicense: "الترخيص",
    StudentActiveLicenseCounts: "الطلبة المرخصين",
    RequestType:"نوع الطلب",
    ActiveStudents: "المرخصين",
    FiledComplaint:"اسم مقدم الشكوي",
    InActiveStudent: "الغير مرخصين",
    AllStudents: "الكل",
    New: "جديد",
    confirmdeleteAd:"هل تريد حذف هذا الاعلان؟",
    image:"صوره",
    EmployeeReport:"تقارير الموظفين",
    ServiceProviderReport:"تقارير مقدمين الخدمات",
    request:"الطلب",
    MarkAsReadAll: "تحديد الكل كمقروئة",
    ScheduleAndEvents: "جدول الحصص والاحداث",
    ScreenName: "اسم الصفحة",
    PushAllNotification:"اشعار الي جميع المستخدمين",
    IsShow: "السماح بالرؤية",
    RoleScreenPermission: "صلاحيات الشاشة",
    Roles: "الصلاحيات",
    IsExam: "امتحان مختلف لكل طالب",
    FeaturedAds:"اعلانات مميزة",
    RecommendedAds:"اعلانات مروجة",
    FeaturedAndRecomended:"اعلانات مروجة و مميزة",
    PleaseAllowNotification: "من فضلك السماح بالاشعارات",
    SendMessage: "ارسال رسالة",
    Contacts: "المحادثات",
    ContactNumber:"رقم التواصل",
    NewExams: "اختبارات جديدة",
    EndedAssignment: "واجبات قديمة",
    NewAssignments: "واجبات جديدة",
    SubjectPerformance: "تقييم المادة",
    EndedExam: "اختبارات قديمة",
    ParentStudentInstallment: "الاقساط",
    OverDue: "تم انتهاء الموعد",
    Paied: "مدفوع",
    UnPaied: "غير مدفوع",
    AssessmentFor: "تقييم علي",
    OverAllGrade: "الدرجات ككل",
    OverAll: "الكل",
    NewAssessment: "تقييمات جديدة",
    FailedExam: "اختبارات لم يتم النجاح بها",
    OverAllPerformance: "التقييم ككل",
    Performance: "التقييم",
    For: "ل",
    TrueAndFalse: "صح او خطأ",
    ShowWithAnswers: "تصحيح الاجابات",
    Assessment: "تقييم الطلبة",
    StudentGrade: "درجة الطالب",
    ExamGrade: "درجة الاختبار",
    TeacherStudentsExamMarks: "تصحيح ودرجات الاختبارات",
    SubjectStageYear: "المادة الدراسية",
    NumberOfQuestions: "عدد الاسئلة",
    "pleasecheckyoure-mailanconfirmationsmssenttoyou": "يرجى التحقق من رسالة التأكيد المرسلة إليك",
    sendcodetomail: "إرسال الرمز إلى الهاتف",
    BackToLogin: "الي الرئيسية",
    "Pleaseenteryouremailaddressandwe'llsendyouInstructionsOnHowtoresetyourpassword": "يرجى إدخال عنوان بريدك الإلكتروني وسنرسل لك تعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك",
    Recoveryourpassword: "استعادة كلمة المرور",
    ChangePassword: "اعادة تعيين كلمة المرور",
    SaveChanges: "حفظ",
    PasswordUpdated: "تم تحديث كلمة السر",
    YourPasswordHasBeenUpdatedSuccessfully: "لقد تم تحديث كلمة السر الخاصة بك بنجاح",

    isInTrip: "في رحلة ؟",
    Note: "ملحوظة",
    maxScore: "أقصى درجة",
    DaysOfWeek: "اليوم",
    PickupLocation: "العنوان",
    TIMEOFEND: "تاريخ الانتهاء",
    TIMEOFSTART: "تاريخ البدء",
    BusTrips: "رحلة الاتوبيس",
    BusTrip: "رحلة الاتوبيس",

    Pickup: "مكان استلام",
    Destination: "مكان التوصيل",
    Both: "الكل",
    Content: "المحتوى",
    Clint:"عميل",
    DeleteAd:"حذف الاعلان",
    SubjectExamRole: "قواعد الاختبارات ",
    Youarealreadyloggedin: "قد قمت بتسجيل الدخول بالفعل!",
    LoginAttempt: "محاولة تسجيل الدخول",
    showNoDataAvailable: "لا توجد بيانات متاحة ... يرجى المحاولة مرة أخرى",
    NoNotesAvailable: "لا توجد ملاحظات",
    showError: "حدث خطأ ما. أعد المحاولة من فضلك",
    //Update: "تحديث",
    showUpdate: "تم التحديث بنجاح ...",
    Event: "الحدث",
    ColorCode: "لون الكود",
    Student: "طالب",
    Call:"اتصال",
    WhatsAPP:"واتساب",
    Location:"العنوان",
    "Route-Edit": "تعديل-المسار",
    AddEdit: "اضافة/تعديل",
    DescriptionOfAttachment: "وصف الملحقات",
    Accepted: "موافقة",
    Accept: "تم القبول",
    Refused: "رفض",
    AcceptedOffersReport:"تقرير العروض الموافق عليها",
    Pending: "قيد الانتظار",
    StudentChangeRouteRequestAddEdit: "تعديل/اضافة",
    Order: "طلب",
    StudentChangeRouteRequest: "طلب تغير مسار الحافلة",
    AllRoutesAndBusses: "خطوط النقل و الحافلات",
    studentBusOFF: "عدد الطلاب",
    StudentBusOffForWeek: "طلبات الغاء الحافلة ",
    AllChangeRouteRequestAccept: "عدد طلبات تغيير المسار المقبولة",
    AllChangeRouteRequestRefused: "عدد طلبات تغيير المسار المرفوضة",
    AllChangeRouteRequestPending: " عدد طلبات تغيير المسار المعلقة",
    AllChangeRouteRequest: " عدد طلبات تغيير الحافلة",
    UnPaidInstallmentCount: "الاقساط غير المسددة",
    PaidInstallmentCount: " الأقساط المدفوعة",
    AlltInstallmentCounts: "جميع الأقساط",
    Url:"المسار",
    InstallmentYearStartDate: "تاريخ بدء الاقساط",
    InstallmentYearEndDate: "تاريخ انتهاء الاقساط",
    EventsForWeek: " أحداث الأسبوع",
    ExamsForWeek: " الاختبارات لمدة أسبوع",
    drivers: "السائقين",
    teachers: "المعلمين",
    students: "الطلاب",
    BannerAds:"اداره محتوي الاعلانات",
    parents: "الاباء",
    AllUsers: " جميع المستخدمين",
    ExamsAndEventsForWeek: " الاختبارات والفعاليات للأسبوع",
    StudentInstallmentCounts: "مجموع أقساط الطلاب",
    Filters: "البحث",
    RelaredAccounts: " الحسابات ذات الصلة",
    Data: " البيانات",
    SendChannelBook: "إرسال مراسلة",
    Send: "ارسال",
    RememberMe: "تذكرنى",
    WelcomeBack: "مرحبًا بك من جديد ، يرجى تسجيل الدخول إلى حسابك.",
    User: "مستخدم",
    StudentTeacherInstallments: "اقساط الطلاب/المستخدمين",
    CustomUser: "مستخدم معين",
    AllDriver: "كل السائقين",
    AllTeacher: "كل المدرسين",
    AllParent: "كل اولياء الامور",
    Parent: "والي امر",
    ShowAds:"رؤيه الاعلانات",
    propertyevaluationrequests:"طلبات التقييم العقارى",
CountryCode:"كود الولة",
    True: "نعم",
    False: "لا",
    BrokerReport:"تقارير مقدمين العقارات",
    Active: "فعال",
    AdsDetails:"تفاصيل الاعلان",
    Inactive: "موقوف",
    ExamCode: "كود الاختبار",
    Pdf: "بي دي إف",
    Add:"اضافه",
    //Update:"تعديل",
    Delete:"حذف",
    Print:"طباعه",
    Index:"عرض",
    Video: "فيديو",
    Employees:"موظفين",
    _Pages:"الصفحات",
    Image: "صورة",
    Document: "ملف",
    Logout: "خروج",
    ThisItemAlreadyExist: "هذا البند موجود من قبل",
    AlreadyExist: "موجود من قبل",
    Edit: "تعديل",
    Material: "ملحقات",
    MaterialType: "نوع الماتيريال",
    All: "الكل",
    ClassTeacherSubject: "الفصول",
    AddSubjects: "اضافة مواد",
    EndDate: "نهاية المدة",
    Sender: "المرسل",
    Message: "الرسالة",
    AdDetails:"تفاصيل الاعلان",
    MessageType: "نوع الرسالة",
    IsReplyable: "يمكن الرد",
    Recevier: "المرسل اليه",
    Operation:"العمليات",
    ChannelBook: "المراسلات",
    ChannelBookType: "انواع المراسلات",
    ChannelBookAddEdit: "المراسلات اضافة/ تعديل",
    BusTracking: "تتبع الاتوبيسات",
    ProjectTitle: "مدرستك",
    Home: "الرئيسية",
    CustomerPropertyReport:"تقارير الاعلانات",
    ServiceProviderSubscriptionsReport:"تقارير اشتراكات مقدمي الخدمات",
    AddNew: "أضافة",
    BrokerSubscriptionReport:"تقارير اشتراكات مقدم العقارات",
    NameAr: "الاسم بالعربي",
    CommingMeetings: "الجلسات القادمة",
    ClassName: "الفصل",
    CreatorName: "أسم المستخدم",
    NotAcceptedOffersReport:" تقارير عروض غير مقبوله",
    MeetingsList: "قائمة الجلسات",
    IsPublic: "عام",
    HasRecord: "يوجد تسجيلات",
    StudentMeetingsList: "قائمة الجلسات للطلبة",
    NameEn: "الاسم بالانجليزي",
    NameFr: "الاسم بالفرانساوي",
    AddressAr: " العنوان بالعربي ",
    AddressEn: "العنوان بالانجليزي",
    AddressFr: "العنوان بالفرانساوي",
    NewMaterial: "اضافة ملحق",
    NomperOfdays:"عدد الايام",
    ViewMaterial: "الملحقات",
    Category:"الفئة",
    KeyWord:"كلمة البحث",
    PropertyName:"اسم الخاصية",
    SearchKeyWords:"كلمات البحث",
    NoteAr: "ملاحظة بالعربي",
    NoteEn: "ملاحظة بالانجليزي",
    NoteFr: "ملاحظة بالفرانساوي",
    StudentUserInstallment: "اقساط الطلاب/المستخدمين",
    AboutUsAr: " معلومات عنا بالعربي",
    AboutUsEn: " معلومات عنا بالانجليزي",
    AboutUsFr: " معلومات عنا بالفرانساوي",
    Logo: "لوجو",
    SendVertificationCode: "ارسال رمز التعريف",
    Areyousureyouwantdeletethisrecord: "هل أنت متأكد أنك تريد حذف هذا السجل",
    Areyousureyouwanttoaccepttheisrequest: "هل أنت متأكد أنك تريد قبول هذا الطلب ؟",
    Previewtherequest:"معاينة الطلب",
    AreyousureyouwantActivethisStudent: "هل أنت متأكد أنك تريد تفعيل هذا الطالب",
    Cancel: "الغاء",
    //Delete: "حذف",
    PhoneNumber: "رقم الهاتف",
    VertificationCode: "رمز التعريف",
    Vertify: "تاكيد الرمز",
    MainInfo: "البيانات الاساسية",
    LoginInfo: "بيانات الدخول",
    Save: "حفظ",
    Email: "البريد الاكتروني",
    Password: "الرقم السري",
    Buses: "الاتوبيسات",
    Bus: "اتوبيس",
    Attachment: "الملحقات",
    Installments: "الأقساط",
    Installment: "ألقسط",
    Stage: "المرحلة",
    StageYear: "السنة",
    Class: "الفصل",
    Age: "السن",
    Subjects: "المواد",
    NewAttachment: "ملحق جديد",
    AddAttachment: "اضافة ملحق",
    Description: "الوصف",
    URL: "الرابط",
    File: "الملف",
    NewBus: "اضافة اتوبيس",
    AddBus: "اضافة اتوبيس",
    ModelAr: "النموذج بالعربي",
    ModelEn: "النموذج بالانجليزي",
    ModelFr: "النموذج بالفرنساوي",
    IsActive: "فعال",
    InstallmentTypeEn: "اسم نوع القسط بالعربي",
    AddInstallment: "أضافة قسط",
    NewInstallment: "قسط جديد",
    PayDate: "تاريخ الدفع",
    IsPaid: "الدفع",
    Paid: "دفع",
    Routes: "الطرق",
    Users: "المستخدمين",
    Students: "الطلاب",
    YouAreAboutToAddThisAssessment: "أنت على وشك إضافة هذا التقييم?",
    Teachers: "المعلمين",
    Drivers: "السائقين",
    ManagingRole:"اداره صلاحيه الاعضاء",
    AreyousureyouwanttoDenytheisrequest:"هل تريد تجاهل هذا الاعلان",
    ExaminationManagement: "أدارة الاختبارات",
    QuestionBank: "بنك الاسئلة",
    ExamRule: "قواعد الاختبار",
    ExamGeneration: "انشاء الاختبار",
    Setting: "الاعدادات",
    SchoolInfo: "بيانات المدرسة",
    AssessmentType: "نوع التقيم",
    BusSuperVisors: "مشرفين الاتوبيسات",
    Language: "اللغة",
    GradeType: "نوع الدرجة",
    PleaseSelectAbsenceStudents: "يرجى تحديد الطلاب الغائبين",
    ExamType: "نوع الاختبار",
    QuestionLevel: "مستوي السؤال",
    TotalAttendees: "عدد الحاضرين",
    TotalAbsence: "عدد الغائبين",
    QuestionType: "نوع السؤال",
    Stages: "المراحل الدراسية",
    StageYears: "سنين المراحل الدراسية",
    InstallmentType: "نوع القسط",
    Name: "الاسم",
    FixedExam: "امتحان ثابت",
    CustomerAds:"الاعلانات",
    RandomExam: "امتحان عشوائي",
    Reset: "اعادة",
    UserWithSamePhoneNumberExist: "يوجد مستخدم بنفس رقم الهاتف",
    SomethingWentWrong: "حدث خطأ ما",
    CodeIsNotExist: "رمز التعريف خطأ",
    Welcome: "مرحبا بك فى",
    Date: "التاريخ",
    ClassSessionName: "الحصة",
    SubjectName: "المادة",
    FromDate: "من",
    ToDate: "الي",
    ClassSession: "الحصة",
    ClassSchedule: "جدول الحصص",
    ClassScheduleAddEdit: "جدول الحصص اضافة/ تعديل",
    Status: "الحالة",
    ChangeStatusAd: "هل انت متاكد من تغير حالة الاعلان",
    Modules: "النماذج",
    Newlanguage: "لغة جديدة",
    AddLanguage: "اضافة لغة",
    NewModule: "وحدة جديد",
    AddModule: "اضافة وحدة",
    ThisModuleAlreadyExists: "هذه الوحدة موجودة بالفعل",
    ThisSubjectAlreadyExists: "هذه المادة موجودة بالفعل",
    ThisDriverAlreadyExists: "هذه السائق موجودة بالفعل",
    Module: "الوحدة",
    Route: "الطريق",
    Details: "التفاصيل",
    IsDeny:"تم رفض الدخول",
    DenyReason : "سبب الرفض",
    IsAllow: "تم الدخول",
    Reason:"السبب",
    AddRouteDetails: "اضافة تفاصيل الطريق",
    Longitude: "خط الطول",
    Latitude: "خط العرض",

    NewRouteDetails: "تقاصيل طريق جديدة",
    NewRequest:"طلب جديد",
    Model: "النموذج",
    BusSupervisor: "مشرف الاتوبيس",
    Driver: "السائق",
    DriverAddEdit: "السائق اضافة/ تعديل",
    AddDriver: "اضافة سائق",
    NewDriver: "سائق جديد",
    Classes: "الفصول",
    NewSubject: "مادة جديدة",
    Subject: "المادة",
    Teacher: "المعلم",
    NewClass: "فصل جديد",
    TeacherAddEdit: "المعلم اضافة/ تعديل",

    AddSubject: "اضافة مادة",
    SubjectMaxScore: "درجة المادة",
    StudentExam: "اختبارات الطالب",
    QuestionNum: "رقم السؤال",
    QuestionAr: "السؤال بالعربي",
    QuestionEn: "السؤال بالانجليزي",
    QuestionFr: "السؤال بالفرانساوي",
    NewQuestion: " سؤال جديد",
    Question: "السؤال",
    Answers: "الاجابات",
    AnswerAr: "الاجابة بالعربي",
    AnswerEn: "الاجابة بالنجليزي",
    AnswerFr: "الاجابة بالفرانساوي",
    IsRight: "صحيح",
    Addanswer: "اضافة اجابة",
    DescriptionAr: "الوصف بالعربي",
    DescriptionEn: "الوصف بالانجليزي",
    DescriptionFr: "الوصف بالفرانساوي",
    Download: "تحميل",
    Type: "النوع",
    AddRule: "اضافة قاعدة",
    NewRule: "قاعدة جديدة",
    RuleInfo: "بيانات القاعدة",
    ExamStartDate: "تاريخ الاختبار",
    Exams: "الاختبارات",
    TimeOfExamInMinutes: "مدة الاختبار بالدقائق",
    Generate: "انشاء",
    ExamMainInfo: "بيانات الاختبار",
    GenerateQuestions: "انشاء الاسئلة",
    Text: "مقالي",
    TrueFalse: "صح او خطأ",
    MultiChoose: "متعدد الاختيارات",
    TimeInMinute: "المدة بالدقائق",
    Weight: "الدرجة",
    TypeAr: "النوع بالعربي",
    TypeEn: "النوع بالانجليزي",
    TypeFr: "النوع بالفرانساوي",
    //UPDATE: "تعديل",
    Amount: "المبلغ",
    InstallmentDate: "تاريخ القسط",
    DeadLine: "الموعد النهائي",
    LevelAr: "المستوي بالعربي",
    LevelEn: "المستوي بالانجليزي",
    LevelFr: "المستوي بالفرانساوي",
    EventType: "نوع الحدث",
    Events: "الاحداث",
    SchoolArchitecture: "اساسيات المدرسة",
    UserType: "نوع المستخدم",
    RelatedAccounts: "الحسابات المرتبطة",
    UserManagment: "ادارة المستخدمين",
    SubjectManagment: "ادارة المواد",
    StageYearSubjects: "مواد المرحلة الدراسية",
    SubjectModules: "ملحقات المادة",
    BuseManagment: "ادارة الاتوبيسات",
    EventManagment: "ادارة الاحداث",
    InstallmentManagment: "ادارة الاقساط",
    Grade: "الدرجة",
    Success: "نجاح",
    CreatedSuccessfully: "تم الاضافة بنجاح",
    UpdatedSuccessfully: "تم التعديل بنجاح",
    Error: "خطا",
    CreatedFailed: "لم يتم الاضافة",
    UpdatedFailed: "لم يتم التعديل",
    DeletedSuccessfully: "تم الحذف بنجاح",
    DeleteFailed: "لم يتم الحذف",
    Confirm: "تاكيد",
    DeleteConfirmText: "هل انت متاكد من انك تريد الحذف",
    ErrorTryLater: "حث خطا ما برجاء المحاولة في وقت لاحق",
    Dashboard: "احصائيات",
    eCommerce: "احصائيات",
    StudentExamAnswer: "اجابات الطالب",
    UpdateImage: "اضافة الصورة",
    RemoveImage: "حذف الصورة",
    Total: "الاجمالي",
    Color: "اللون",
    showChat: "عرض الرسائل",
    CodePhoneNumberHint: "برجاء ادخال كود البلد (+2)",
    PropertyManagement:"ادارة الأملاك",
    RealEstateDevelopment:"التطوير العقارى",
    RealEstateContributions:"المساهمات العقارية",
    SpecialRequests:"الطلبات الخاصة",
    PropertyManagementrequests:"طلبات ادارة الأملاك",
    contributionsrequests:"طلبات المساهمات العقارية",
    developmentrequests:"طلبات التطوير العقارى",
    Apps: "Apps",
    close:"أغلاق",
    Todo: "Machen",
    Chat: "Plaudern",
    Calendar: "Kalender",
    FullCalendar: "Calendrier completVollständiger Kalender",
    SimpleCalendar: "Einfacher Kalender",
    Shop: "Geschäft",
    Cart: "Wagen",
    WishList: "Wunschzettel",
    Checkout: "Auschecken",
    UI: "UI",
    DataList: "Datenliste",
    ListView: "Listenansicht",
    ThumbView: "Daumenansicht",
    Grid: "Gitter",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Farben",
    Card: "Karte",
    Basic: "Basic",
    Statistics: "Statistiken",
    Analytics: "Analytics",
    CardActions: "Kartenaktionen",
    CardColors: "Kartenfarben",
    Table: "Tabelle",
    agGridTable: "agGrid-Tabelle",
    Components: "Komponenten",
    Alert: "Warnen",
    Avatar: "Benutzerbild",
    Badge: "Abzeichen",
    Breadcrumb: "Breadcrumb",
    Button: "Taste",
    ButtonGroup: "Schaltflächengruppe",
    Chip: "Chip",
    Collapse: "Zusammenbruch",
    Dialogs: "Dialoge",
    Divider: "Teiler",
    DropDown: "Dropdown-Liste",
    List: "Liste",
   // Loading: "Wird geladen",
    Navbar: "Navbar",
    Pagination: "Seitennummerierung",
    Popup: "Pop-up",
    Progress: "Fortschritt",
    Sidebar: "Seitenleiste",
    Slider: "Schieberegler",
    Tabs: "Tabs",
    Tooltip: "QuickInfo",
    Upload: "Hochladen",
    FormsAndTable: "Formulare und Tabelle",
    FormElements: "Formularelemente",
    Select: "Wählen",
    Switch: "Schalter",
    Checkbox: "Ankreuzfeld",
    Radio: "Radio",
    Input: "Eingang",
    NumberInput: "Nummer eingeben",
    Textarea: "Textbereich",
    FormLayouts: "Formularlayouts",
    FormWizard: "Formzauberer",
    FormValidation: "Formularvalidierung",
    FormInputGroup: "Formulareingabegruppe",
    Pages: "Seiten",
    Authentication: "Authentifizierung",
    Login: "دخول",
    Register: "تسجيل",
    ForgotPassword: "نسيت كلمة المرور؟",
    ResetPassword: "اعادة تعيين كلمة المرور",
    LockScreen: "Bildschirm sperren",
    Miscellaneous: "Verschiedenes",
    ComingSoon: "Demnächst",
    404: "404",
    500: "500",
    NotAuthorized: "Nicht berechtigt",
    Maintenance: "Instandhaltung",
    Profile: "صفحتي",
    Invoice: "Rechnung",
    FAQ: "FAQ",
    Search: "بحث",
    KnowledgeBase: "Wissensbasis",
    ChartsAndMaps: "Diagramme und Karten",
    Charts: "Diagramme",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Karte",
    Extensions: "Erweiterungen",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Ziehen und loslassen",
    Datepicker: "Datumsauswahl",
    DatetimePicker: "Datum / Uhrzeit-Auswahl",
    AccessControl: "Zugangskontrolle",
    I18n: "I18n",
    Carousel: "Karussell",
    Clipboard: "Zwischenablage",
    ContextMenu: "Kontextmenü",
    StarRatings: "Sterne Bewertungen",
    Autocomplete: "Autovervollständigung",
    Tree: "Baum",
    Import: "Einführen",
    Export: "Export",
    ExportSelected: "Auswahl exportieren",
    Others: "Andere",
    MenuLevels: "Menüebenen",
    MenuLevel2p1: "Menüebene 2.1",
    MenuLevel2p2: "Menüebene 2.2",
    MenuLevel3p1: "Menüebene 3.1",
    MenuLevel3p2: "Menüebene 3.2",
    DisabledMenu: "Deaktiviertes Menü",
    Support: "Unterstützung",
    Documentation: "Dokumentation",
    RaiseSupport: "Unterstützung erheben",
    demoTitle: "Kartentitel",
    demoText: "Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.",
    TermsOfUse: "Gebruiks voor waarden",
    PrivacyPolicy: "Privacy beleid",
    Imprints: "Opdrukken",

  }
};
