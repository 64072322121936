/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import axios from "@/axios.js";

// import firebase from 'firebase/app'
import "firebase/auth";
//import router from "@/router";
export default {
  // JWT
  loginJWT({ commit }, payload) {
    //
    return new Promise((resolve, reject) => {

      jwt
        .login(
          payload.userDetails.UserName,
          payload.userDetails.password,
          payload.userDetails.Language,
          payload.userDetails.userType,
          payload.userDetails.fireBaseToken,
          payload.userDetails.phoneNumber,

        )
        .then(response => {
          if(response.data.StatusCode==201){
            resolve(response);

          }
          // If there's user data in response
         else if (response.data) {
            // Navigate User to homepage
            //   router.push(router.currentRoute.query.to || '/')

            // Set token
             debugger
            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem("expiresIn", response.data.data.expiresIn);
            localStorage.setItem("accessToken", response.data.data.accessToken);
            localStorage.setItem("ScreenRolesPermissions",JSON.stringify( response.data.data.ScreenRolesPermissions));

            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            );
            if (response.data.data.userData.teacherData != null) {
              response.data.data.userData.TeacherID =
                response.data.data.userData.teacherData.ID;
            }
            // Update user details
            commit("UPDATE_USER_INFO", response.data.data.userData, {
              root: true
            });
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response.data);
          } else {
            reject({ message: "Wrong Email or Password" });
          }
        })
        .catch(error => {
          //
          reject(error);
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    console.log("ttttt",commit);
   debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Auth/Registration", payload)
        .then(response => {
          // Handle successful registration
          if (response.data.StatusCode === 201) {
            debugger;
            console.log(response);
            // Redirect to the login page after successful registration
           // this.$router.push('/page-login');
            resolve(response);
          } else {
            debugger;
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
   fetchAccessToken() {
    //
    return new Promise(resolve => {
      jwt
        .refreshToken()
        .then(response => {
          resolve(response);
        })
        .catch(function() {
          //
          //router.push("/pages/login");
        });
    });
  },
  UpdateFCMToken({commit} ,token){
    debugger
    var model = {};
    model.refreshToken = localStorage.getItem("refreshToken");
    model.FirebaseToken = token;
    return new Promise((resolve) => {
      axios
        .post("/API/Auth/Refresh",model)
        .then(response => {
          if (response.data) {

            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem("expiresIn", response.data.data.expiresIn);
            localStorage.setItem("accessToken", response.data.data.accessToken);
            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            );
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response.data);
          }
        })
    });
  },
  logout({ commit }) {
    debugger;
    // Set accessToken
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    // Update user details
    commit("REMOVE_USER_STORE", null, { root: true });
    commit("CLEAR_USER_INFO", null, { root: true });
    // Set bearer token in axios
    commit("REMOVE_BEARER");
  },
  ChangePassword(context, model) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {

      axios
        .post("/api/Auth/ChangePassword", model,{headers:Token})
        .then(response => {

          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SendVerifyCodeForPhone(context, PhoneNumber) {
    return new Promise((resolve, reject) => {

      axios
        .post(
          "/API/Auth/SendOTP?PhoneNumber=" +
            PhoneNumber +
            "&isResetPassword=" +
            true
        )
        .then(response => {

          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CheckCode(context, model) {
    return new Promise((resolve, reject) => {

      axios
        .post(
          "/API/Auth/CheckCode?PhoneNumber=" +
            model.PhoneNumber +
            "&Code=" +
            model.Code
        )
        .then(response => {

          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // ResetPassword(context, model) {
  //   return new Promise((resolve, reject) => {

  //     axios
  //       .post(
  //         "/API/Authentication/ResetPassword?phoneNumber=" +
  //           model.phoneNumber +

  //           "&newPassword=" +
  //           model.newPassword
  //       )
  //       .then(response => {

  //         console.log(response);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  ResetPassword(context, model) {
    debugger;
    return new Promise((resolve, reject) => {
        axios
            .post(
                "/API/Auth/ResetPassword?phoneNumber=" + model.PhoneNumber +"&code="+ model.code+"&newPassword=" + model.newPassword
            )
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
},

  GetUser(context, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .get("/API/Auth/GetUser?userID=" + itemid)
        .then(response => {
          resolve(response);

          // commit('UPDATE_QuestionBank', response.data.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  EditProfile(context, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post("API/Account/EditProfile", itemid)
        .then(response => {
          resolve(response);

          // commit('UPDATE_QuestionBank', response.data.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //notification
  GetAllNewNotification({ commit }) {
    debugger;
    commit("RemoveFrom_AllNotificationList", [], { root: true });
    return new Promise((resolve, reject) => {
      axios
        // .get(`/Notification/GetAllNewNotification`)
        .get(`GetUserNotification`)
        .then(response => {
           debugger
           if(response.data.data!=null&&response.data.data!=undefined&&response.data.data.length>0)
         {
          response.data.data.forEach(element => {
            commit("UPDATE_NotificationList", element, { root: true });
          });
         }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  MarkIsReadNotification(context, ID) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/API/Notification/setNotificationAsRead?id=${ID}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  // MarkIsNewNotificationByNotify(context, ID) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/Notification/MarkIsNewNotificationByNotify?ID=${ID}`)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  // DeleteAllNotification() {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/Notification/DeleteAllNotification`)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }
};
